import { connect } from 'react-redux';
import HeroBanner from './hero_banner';

const mapStateProps = ({ user: { user } }) => ({
  userBonusProgram: user && user.cardValidationBonusInfo,
  userMatchUpBonusInfo: user && user.matchUpBonusInfo,
  userShowMatchUpBonusInfo: user && user.showMatchUpBonusInfo,
  userLoading: false
});

export default connect(
  mapStateProps
)(HeroBanner);
