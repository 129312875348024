import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';
import { trackQueuedEvents, facebookPixelScript, facebookPixelNoscript } from '../../actions/facebook_analytics';
import { hotJarInitialize, hotJarIdentifyUser } from '../../actions/hotjar';
import { sectionIds } from '../home_page/prop_types';
import { gogleLoginScript } from '../registration/inner_components/google_btn';
import { getAppTheme, fromMobileApp } from '../../../common/config/utils';
import { appThemes } from '../../../common/config/const';
import { trackQueuedOptimoveEvents, trackFirstVisitEventGtm } from '../../actions/google_tag_mahager_events';
import env from '../../config/variables';
import { isPrerenderMode } from '../../config/util';

const { shared } = window;

const cookies = new Cookies();

const cookieValue = (valueName) => {
  const valueString = document.cookie.split('; ').find(row => row.startsWith(`${ valueName }=`));
  const value = valueString ? valueString.split('=')[1] : null;
  return value;
};

const { PAYMENT_PROCESSING_INTERVAL_SEC } = env;

function AnalyticsAndCommonLogicComponent({
  userId,
  configureMP,
  trackPeoplePropertiesAction,
  getUser,
  initFirebase,
  requestPermission,
  uploadLogs,
  logKey,
  logUrl,
  parseAndUploadLogs,
  identifyOrAliasUserOnMP,
  user,
  loadHomePage,
  userPaymentProcessing,
  getMyAccountCredits,
  appLoading
}) {
  const setAppTheme = () => {
    const appTheme = getAppTheme();
    if (appTheme === appThemes.dark) {
      document.body.classList.add(`${ shared.darkTheme }`);
    }
  };
  useEffect(() => {
    setAppTheme();
    setTimeout(() => appLoading(), 1000);
    if (!cookieValue('visited')) {
      trackFirstVisitEventGtm();
      cookies.set('visited', true, {
        path: '/'
      });
    }
  }, []);

  useEffect(() => {
    getUser(window.currentUserId);
    initFirebase();
    configureMP();
    hotJarInitialize();
    if (!fromMobileApp && !isPrerenderMode()) {
      loadHomePage(false, { section_ids: [sectionIds.specialities, sectionIds.salesBanner].join() });
    }
  }, []);

  useEffect(() => {
    trackQueuedEvents();
  }, [window.facebookPixelConfigured, window.FB, userId]);

  useEffect(() => {
    if (window.optimoveSDK && window.optimoveSDK !== undefined) {
      trackQueuedOptimoveEvents();
    }
  }, [window.optimoveSDK]);

  useEffect(() => {
    if (!userId) return;
    identifyOrAliasUserOnMP();
    trackPeoplePropertiesAction();
  }, [userId]);

  useEffect(() => {
    if (!userId) return;
    hotJarIdentifyUser({ user });
  }, [user]);

  useEffect(() => {
    if (!userId) return;
    requestPermission();
  }, [userId]);

  useEffect(() => {
    if (uploadLogs) {
      parseAndUploadLogs(logUrl, logKey);
    }
  }, [uploadLogs]);

  useEffect(() => {
    if (userPaymentProcessing) {
      const interval = setInterval(() => {
        getMyAccountCredits();
      }, PAYMENT_PROCESSING_INTERVAL_SEC * 1000);
      return () => {
        if (interval) clearInterval(interval);
      };
    }
    return () => {};
  }, [userPaymentProcessing]);

  const addFacebookPixelNoscript = () => {
    if (!document.getElementById('facebook_pixel_noscript')) {
      document.body.appendChild(facebookPixelNoscript());
    }
  };

  const addScriptsToBody = () => {
    addFacebookPixelNoscript();
  };

  const addScripts = () => (
    <>
      <Helmet>
        {facebookPixelScript()}
        {gogleLoginScript()}
      </Helmet>
      { addScriptsToBody() }
    </>
  );

  return addScripts();
}

AnalyticsAndCommonLogicComponent.propTypes = {
  configureMP: PropTypes.func.isRequired,
  trackPeoplePropertiesAction: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  initFirebase: PropTypes.func.isRequired,
  requestPermission: PropTypes.func.isRequired,
  userId: PropTypes.number,
  uploadLogs: PropTypes.bool,
  logUrl: PropTypes.string,
  logKey: PropTypes.string,
  parseAndUploadLogs: PropTypes.func.isRequired,
  identifyOrAliasUserOnMP: PropTypes.func.isRequired,
  user: PropTypes.object,
  loadHomePage: PropTypes.func.isRequired,
  userPaymentProcessing: PropTypes.bool,
  getMyAccountCredits: PropTypes.func.isRequired,
  appLoading: PropTypes.func.isRequired
};

AnalyticsAndCommonLogicComponent.defaultProps = {
  userId: null,
  uploadLogs: false,
  logUrl: null,
  logKey: null,
  user: null,
  userPaymentProcessing: null
};

export default AnalyticsAndCommonLogicComponent;
