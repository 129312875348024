import {
  logInAPI,
  registerAPI,
  authSocAPI,
  logOutAPI
} from '../config/api';
import Const from '../config/const';
import {
  resetMP,
  identifyOrAliasUserOnMP
} from './mixpanel';
import { hotJarReset } from './hotjar';
import { trackLoginEvent, trackRegisterEvent, trackLogoutEvent } from './analytics';
import { getDataFromStorage, saveDataToStorage } from '../config/util';
import { toCamelCase } from '../../common/config/utils';
import { log } from '../config/app_logger';
import { loadHomePage } from './home_page';
import { GDPRConsernPopupThemes } from '../config/themes';

const operationsStatuses = {
  no_consent_privacy_policy: 'noConsent'
};

export const shouldShowGDPRConsent = (shouldDisplay, socialParams) => (dispatch) => {
  dispatch({
    type: Const.auth.showGDPRConsernPopup,
    display: shouldDisplay,
    socialParams
  });
};

const authProgressActionCreator = () => ({
  type: Const.auth.loading
});

export const authErrorActionCreator = error => ({
  type: Const.auth.error,
  payload: {
    error
  }
});

const authSuccessActionCreator = (user) => ({
  type: Const.auth.success,
  payload: {
    user
  }
});

export const navigationPop = () => ({
  type: Const.auth.navigationPop
});

export const authClearPendingAction = () => ({
  type: Const.auth.clearPendingAction
});

export const authClearPendingCloseAction = () => ({
  type: Const.auth.clearPendingCloseAction
});

export const changeScreen = (screen) => ({
  type: Const.auth.screenChange,
  nextScreen:screen
});

export const updateCurrentScreen = (screen) => ({
  type: Const.auth.updateCurrentScreen,
  newScreen:screen
});

export const gdprSwitcherValueChanged = (value) => ({
  type: Const.auth.consentToGDPRChanged,
  value
});

export const displayRegister = ({ shouldDisplay, signUpFlow, screen }) => {
  const wasLogin = getDataFromStorage('WAS_LOGIN');
  const getScreen = () => {
    if (screen) return screen;
    return wasLogin ? Const.authScreens.signIn : Const.authScreens.signUp;
  };
  return ({
    type: Const.auth.displayRegister,
    shouldDisplay,
    signUpFlow,
    currentScreen: getScreen()
  });
};

export const clearError = () => ({
  type: Const.auth.clearError
});

export const authPendingAction = pendingAction => dispatch => {
  const pendingDecorator = () => {
    pendingAction();
    dispatch(authClearPendingAction());
    dispatch(authClearPendingCloseAction());
  };
  dispatch({
    type: Const.auth.pendingAction,
    payload: {
      pendingAction: pendingDecorator
    }
  });
};

export const authPendingCloseAction = pendingCloseAction => dispatch => {
  const pendingCloseDecorator = () => {
    pendingCloseAction();
    dispatch(authClearPendingCloseAction());
    dispatch(authClearPendingAction());
  };
  dispatch({
    type: Const.auth.pendingCloseAction,
    payload: {
      pendingCloseAction: pendingCloseDecorator
    }
  });
};

const authHandler = (promise, dispatch, socialParams) => new Promise(async (resolve, reject) => promise
  .then(user  => {
    const {
      user: { id }
    } = user;
    window.currentUserId = id;
    dispatch(authSuccessActionCreator(user));
    saveDataToStorage('WAS_LOGIN', true);
    resolve(user);
  })
  .catch(error => {
    error
      .json()
      .then(jsonError => {
        const { errors, operationStatus } = toCamelCase(jsonError);
        if (operationsStatuses[operationStatus]) {
          dispatch(shouldShowGDPRConsent({ [GDPRConsernPopupThemes.social]: true }, socialParams));
          return;
        }
        dispatch(authErrorActionCreator(errors || jsonError));
        reject(errors || jsonError);
      })
      .catch(e => {
        dispatch(authErrorActionCreator(e));
        reject(e);
      });
  }));

export const authSocial = (socRequest, signupFlow) => (dispatch, getState) => {
  const { consentedToGDPR, loading } = getState().auth;
  if (loading) return;
  dispatch(authProgressActionCreator());
  const params = { ...socRequest, consentedToGDPR };
  const socialParams = { socRequest, signupFlow };
  authHandler(authSocAPI(params)
    .then(user => {
      dispatch(identifyOrAliasUserOnMP(user));
      return user;
    }), dispatch, socialParams)
    .then(user => {
      if (user) {
        if (user.user.justSignedUp) {
          dispatch(trackRegisterEvent(signupFlow, user.conversionEventId));
        } else {
          dispatch(trackLoginEvent(signupFlow));
        }
      }
    }).catch(e => { log('AUTh log in error', e); });
};

export const logIn = (email, password, rememberMe, signupFlow, recaptchaToken) => (dispatch, getState) => {
  const { loading } = getState().auth;
  if (loading) return;
  dispatch(authProgressActionCreator());
  authHandler(logInAPI(email, password, rememberMe, recaptchaToken), dispatch)
    .then(user => {
      dispatch(identifyOrAliasUserOnMP(user));
      return user;
    })
    .then(user => {
      if (user) {
        dispatch(loadHomePage(true));
        dispatch(trackLoginEvent(signupFlow));
      }
    }).catch(e => { log('AUTh log in error', e); });
};

export const register = (email, password, rememberMe, signupFlow, recaptchaToken) => (dispatch, getState) => {
  const { loading } = getState().auth;
  if (loading) return;
  dispatch(authProgressActionCreator());
  authHandler(registerAPI(email, password, rememberMe, recaptchaToken)
    .then(user => {
      dispatch(loadHomePage(true));
      dispatch(identifyOrAliasUserOnMP(user));
      return user;
    }), dispatch)
    .then(user => {
      if (user) {
        if (user.user.justSignedUp) {
          dispatch(trackRegisterEvent(signupFlow, user.conversionEventId));
        } else {
          dispatch(trackLoginEvent(signupFlow));
        }
      }
    }).catch(e => { log('AUTh log in error', e); });
};

const logOutLoading = () => ({
  type: Const.logOut.loading
});

const logOutSuccess = () => ({
  type: Const.logOut.success
});

export const logOutClear = () => {
  window.currentUserId = 0;
  return ({
    type: Const.logOut.clear
  });
};
export const logOutAction = () => (dispatch) => {
  dispatch(logOutLoading());
  logOutAPI()
    .then(() =>  {
      dispatch(trackLogoutEvent(dispatch));
      dispatch(loadHomePage(true));
      resetMP();
      hotJarReset();
      window.currentUserId = 0;
      dispatch(logOutSuccess());
    })
    // eslint-disable-next-line no-console
    .catch((error) => log('ERROR logging out!', error));
};
