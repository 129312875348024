import { connect } from 'react-redux';
import PromoCode from './promo_code';
import { applyCode, clearModalText } from '../../actions/promo_code';
import { authPendingAction, displayRegister } from '../../actions/auth';
import withPGTranslation from '../../config/withPGTranslation';

const mapStateProps = ({ promoCode }) => promoCode;

export default connect(mapStateProps, {
  applyCode,
  clearModalText,
  displayRegister,
  authPendingAction
})(withPGTranslation(PromoCode));
