import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import AdvisorAvatar, { advisorThemes } from '../../../advisor_avatar';
import { SectionDefaultProps } from '../../prop_types';
import AdvisorModes from '../advisor_modes/advisor_modes';
import PriceText from '../price_text/price_text';
import classes from './classes.module.scss';
import AppLink from '../../../routes/app_link';
import {
  isMobile, isXfmProgram, getXfmFreeSetupMin, subscribedToAdvisor
} from '../../../../config/util';
import ReadingSinceAndCountItem, { readingSinceAndCountItemThemes } from '../../../advisor/nested_views/readingSinceAndCountItem';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { withAnalytics } from './advisor_preview_with_analytics';
import { isKA, isWebView } from '../../../../../common/config/utils';
import { handleNotifyClick } from '../../../advisor_profile_avatar/top_icons/top_icons_utils';

const image3fm = require('../../img/3fm_image.svg');
const notifyIcon = require('./img/notify_icon.svg');
const notifyIconDisabled = require('./img/notify_icon_disabled.svg');
const notifySetIcon = require('./img/notify_set_icon.svg');

const maxServiceDescriptionChars = 100;

const getadvisorSlug = (advisorSlug) => {
  if (advisorSlug !== undefined) return advisorSlug;
  return '';
};

const skeletonOrItem = ({ item, skeletonWidth, maxChars }) => {
  if (item) {
    if (maxChars) return item.substring(0, maxChars);
    return item;
  }
  return <Skeleton width={ skeletonWidth } />;
};

const renderAdvisorModes = (loading, advisor, text) => <AdvisorModes { ...advisor } loading={ loading } text={ text } />;

const renderPriceText = (loading, advisor) => {
  if (loading) return <Skeleton width={ 70 } />;
  return <PriceText { ...advisor } />;
};

const getKey = (advisor) => (advisor || {}).id;

const getServiceDescriptionRowsCount = () => {
  if (isMobile()) return 3;
  return 4;
};

const handleLinkClickAction = ({
  e, loading, sectionSlug, updateNavigationStack
}) => {
  if (loading) {
    e.preventDefault();
    return;
  }
  if (sectionSlug) { updateNavigationStack({ dataKey: sectionSlug }); }
};

const renderNotifyIcon = () => (isWebView() ? notifyIconDisabled : notifyIcon);

const renderNotifyMeButton = (onNotifyClick, availabilityNotification) => (
  <button disabled={ isWebView() } style={ isWebView() ? { backgroundColor: '#EAEAEA', color: '#A1A1A1' } : {} } type="button" onClick={ onNotifyClick } className={ classes.notifyMeButton }>
    <img src={ subscribedToAdvisor(availabilityNotification) ? notifySetIcon : renderNotifyIcon() } alt="" />
    {subscribedToAdvisor(availabilityNotification) ? 'Notification set' : 'Notify me'}
  </button>
);
const getStartCallMode = (liveModes) => {
  if (!liveModes ||  !liveModes.find(m => m.modeState === 'online')) return null;
  if (liveModes.find(m => m.mode === 'chat').modeState === 'online') return liveModes.find(m => m.mode === 'chat');
  return liveModes.find(m => m.mode === 'voice');
};

export const renderStartCallButton = (advisor, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, location, analytics, handleLinkClick, t) => {
  if (!isKA()) return null;
  const {
    id, liveModes, availabilityNotification, slug
  } = advisor;
  const onNotifyClick = (e) => handleNotifyClick(e, displayNotifyMe, id, showGeneralPopup, authPendingAction, displayRegister, location, false);
  const mode = getStartCallMode(liveModes);
  return mode ? (
    // start call from advisor preview //
    // <AdvisorCta
    //   { ...advisor }
    //   { ...mode }
    //   loading={ loading }
    //   advisorId={ id }
    //   advisorCallProps={ {
    //     id,
    //     name: nickname,
    //     profilePictureUrl
    //   } }
    //   liveModes={ liveModes }
    //   advisorXfmProgram={ xfmProgram }
    //   type="advisorPreviewCta"
    // />
    <AppLink
      to={ {
        pathname: getadvisorSlug(slug),
        state: {  ...analytics, startChatClickSource: (analytics || {}).clickSource }
      } }
      className={ classes.previewAdvisorCTA }
      onClick={ handleLinkClick }
    >
      {t('advisor_profile.connect')}
    </AppLink>
  ) : renderNotifyMeButton(onNotifyClick, availabilityNotification);
};

const renderStartCallButtonContainer = (advisor, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, location, analytics, handleLinkClick, t) => (isKA() ? (
  <div className={ classes.previewAdvisorCTAContainer }>
    {renderStartCallButton(advisor, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, location, analytics, handleLinkClick, t)}
  </div>
) : null);

const adjustAccordingEnv = (valueKa, valuePG) => (isKA() ? valueKa : valuePG);

const renderModesInfo = (renderAdvisorFreeMinutes, loading, advisor, t) => (
  <div className={ adjustAccordingEnv(classes.modesInfoContainer, classes.modesInfoContainerWithIcons) }>
    { renderAdvisorFreeMinutes }
    { adjustAccordingEnv(renderPriceText(loading, advisor), renderAdvisorModes(loading, advisor, t('home_page.from'))) }
  </div>
);

function AdvisorPreview({
  theme, advisor, advisorCount, loading, analytics, content, style, className,
  updateNavigationStack, sectionSlug, onAnalyticsClick, innerRef, t, userXfmProgram,
  displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister
}) {
  const location = useLocation();

  const renderAdvisorFreeMinutes = React.useMemo(() => {
    if (!isXfmProgram({ advisorXfmProgram: advisor?.xfmProgram, userXfmProgram })) return null;
    return (
      <div className={ classes.wrapperFreeMinutes }>
        <div className={ classes.advisorFreeMinutes }>
          <img src={ image3fm } alt="3fm" />
          {skeletonOrItem({ item: `${ getXfmFreeSetupMin() } ${  t('free_minutes.free_min_label')  }`, skeletonWidth: 100 })}
        </div>
      </div>
    );
  }, [advisor?.xfmProgram?.active, userXfmProgram?.credits]);

  const renderAdvisorService = () => (
    <div className={ classes.advisrServiceContainer }>
      <div className={ classes.advisrService }>
        <ReadingSinceAndCountItem
          readingsCount={ advisor.readingsCount }
          yearJoined={ advisor.yearJoined }
          theme={ readingSinceAndCountItemThemes.onHomePageSecriceContainer }
          loading={ loading }
        />
        <div className={ classes.advisornickNameAndServiceContainer }>
          <ExpandedText ellipsis className={ classes.advisorNickNameContainer }>
            <span className={ classes.advisrNickName } suppressHydrationWarning>
              {skeletonOrItem({ item: advisor.nickname, skeletonWidth: 170 })}
            </span>
          </ExpandedText>
          <ExpandedText ellipsis className={ classes.advisorServiceTitleContainer }>
            <span className={ classes.advisorServiceTitle } suppressHydrationWarning>
              {skeletonOrItem({ item: advisor.serviceTitle, skeletonWidth: 170 })}
            </span>
          </ExpandedText>
        </div>
        <ExpandedText
          ellipsis
          className={ classes.serviceDescriptionContainer }
          limit={ getServiceDescriptionRowsCount() }
          textStyle={ { lineHeight: '14px' } }
        >
          <span className={ classes.advisrServiceText } suppressHydrationWarning>
            {skeletonOrItem({ item: advisor.serviceDescription, skeletonWidth: 170, maxChars: maxServiceDescriptionChars })}
          </span>
        </ExpandedText>
      </div>
    </div>
  );

  const handleLinkClick = (e) => {
    if (onAnalyticsClick) { onAnalyticsClick(); }
    handleLinkClickAction({
      e, loading, sectionSlug, updateNavigationStack
    });
  };

  const renderAdvisorContent = () => {
    if (content) return content;
    return (
      <AppLink
        to={ {
          pathname: getadvisorSlug(advisor.slug),
          state: {  ...analytics, startChatClickSource: (analytics || {}).clickSource }
        } }
        className={ classes.advisorViewLink }
        onClick={ handleLinkClick }
      >
        <AdvisorAvatar { ...advisor } theme={ advisorThemes[theme] } loading={ loading } analytics={ analytics  } />
        { renderAdvisorService() }
      </AppLink>
    );
  };

  const renderBottomSection = () => (content || (
    <div className={ classes.bottomSection }>
      {renderModesInfo(renderAdvisorFreeMinutes, loading, advisor, t)}
      {renderStartCallButtonContainer(advisor, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, location, analytics, handleLinkClick, t)}
    </div>
  ));

  const renderAdvisor = () => (
    <div key={ getKey(advisor) } className={ classNames(classes.advisorView, className) } style={ style } suppressHydrationWarning>
      { renderAdvisorContent() }
      {renderBottomSection()}
    </div>
  );

  return (
    <div className={ classes[theme] } style={ { '--advisorCount': `${ advisorCount }` } } ref={ innerRef } suppressHydrationWarning>
      { renderAdvisor() }
    </div>
  );
}

AdvisorPreview.propTypes = {
  advisor: PropTypes.object,
  theme: PropTypes.string,
  advisorCount: PropTypes.number,
  advisorIndex: PropTypes.number,
  loading: PropTypes.bool,
  analytics: PropTypes.object,
  content: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  updateNavigationStack: PropTypes.func.isRequired,
  sectionSlug: PropTypes.string,
  t: PropTypes.func.isRequired,
  onAnalyticsClick: PropTypes.func,
  innerRef: PropTypes.object,
  userXfmProgram: PropTypes.object,
  displayNotifyMe: PropTypes.func.isRequired,
  showGeneralPopup: PropTypes.func.isRequired,
  authPendingAction: PropTypes.func.isRequired,
  displayRegister: PropTypes.func.isRequired
};

AdvisorPreview.defaultProps = {
  ...SectionDefaultProps,
  advisor: null,
  loading: null,
  theme: 'badge',
  iconUrl: null,
  name: null,
  advisorCount: 1,
  analytics: null,
  content: null,
  style: null,
  className: null,
  sectionSlug: null,
  onAnalyticsClick: null,
  innerRef: null,
  userXfmProgram: {}
};

// wrap here in case someone wants to include it directly
export default withAnalytics(AdvisorPreview);
