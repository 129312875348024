import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import PurchaseDetails from '../chat_overlay/purchase_details';
import PromoCode from '../promo_code';
import Const, { promoCodeTheme } from '../../config/const';
import { isPrerenderMode } from '../../config/util';

const noNeedRenderAffiliatePromoItem = ({ showItem, noThanksLinkClicked }) => {
  const noNeed = !showItem || noThanksLinkClicked || isPrerenderMode();
  return noNeed;
};

const affiliatePromoPopupCloseButtonAction = (noNeedCloseIcon, hideAffiliatePromoPopup) => {
  if (noNeedCloseIcon) return null;
  return hideAffiliatePromoPopup;
};

const affiliatePromoCodeCloseButtonAction = (onSelectDuration, hideAffiliatePromoCode, noThanksLinkClickedAction) => {
  if (onSelectDuration) {
    return () => {
      noThanksLinkClickedAction();
      hideAffiliatePromoCode();
    };
  }
  return hideAffiliatePromoCode;
};

function AffiliatePromoPopup({
  showAffiliatePromoPopup, hideAffiliatePromoPopup, noNeedCloseIcon,
  showAffiliatePromoCode, showAffiliatePromoPopupAfterPromo,
  hideAffiliatePromoCode, chatType, needUpdatePricingOptions, noThanksLinkClicked,
  onSelectDuration, noThanksLinkClickedAction
}) {
  const [needShowAffiliatePromoPopup, setNeedShowAffiliatePromoPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => setNeedShowAffiliatePromoPopup(true), 1000);
  }, []);

  const renderAffiliatePromoPopup = () => {
    const noNeed = noNeedRenderAffiliatePromoItem({
      showItem: showAffiliatePromoPopup, noThanksLinkClicked
    });
    return (
      <AppGeneralPopup
        visible={ !noNeed }
        closeButtonAction={
            affiliatePromoPopupCloseButtonAction(noNeedCloseIcon, hideAffiliatePromoPopup)
          }
        theme={ appGeneralPopupTheme.AFFILIATE_PROMO }
      >
        <PurchaseDetails
          chatType={ chatType }
          hidePaymentDetails = {
            affiliatePromoPopupCloseButtonAction(noNeedCloseIcon, hideAffiliatePromoPopup)
          }
          needUpdatePricingOptions={ needUpdatePricingOptions }
        />
      </AppGeneralPopup>
    );
  };

  const renderAffiliatePromo = () => {
    const noNeed = noNeedRenderAffiliatePromoItem({
      showItem: showAffiliatePromoCode, noThanksLinkClicked
    });
    return (
      <AppGeneralPopup
        visible={ !noNeed }
        closeButtonAction={
            affiliatePromoCodeCloseButtonAction(onSelectDuration, hideAffiliatePromoCode, noThanksLinkClickedAction)
          }
        theme={ appGeneralPopupTheme.AFFILIATE_PROMO }
        needOpenOnAppRoot
      >
        <PromoCode
          theme={ promoCodeTheme.AFFILIATE_PROMO }
          onSussesRead={ showAffiliatePromoPopupAfterPromo }
        />
      </AppGeneralPopup>
    );
  };

  const renderAffiliatePromoPopupContent = () => {
    if (!needShowAffiliatePromoPopup && isPrerenderMode()) return null;
    return (
      <>
        { renderAffiliatePromo() }
        { renderAffiliatePromoPopup() }
      </>
    );
  };

  return renderAffiliatePromoPopupContent();
}

AffiliatePromoPopup.propTypes = {
  showAffiliatePromoPopup: PropTypes.bool.isRequired,
  hideAffiliatePromoPopup: PropTypes.func.isRequired,
  noNeedCloseIcon: PropTypes.bool,
  showAffiliatePromoCode: PropTypes.bool.isRequired,
  showAffiliatePromoPopupAfterPromo: PropTypes.func.isRequired,
  hideAffiliatePromoCode: PropTypes.func.isRequired,
  chatType: PropTypes.string,
  needUpdatePricingOptions: PropTypes.bool,
  noThanksLinkClicked: PropTypes.bool.isRequired,
  onSelectDuration: PropTypes.bool,
  noThanksLinkClickedAction: PropTypes.func.isRequired
};

AffiliatePromoPopup.defaultProps = {
  noNeedCloseIcon: false,
  chatType: Const.chatType.text,
  needUpdatePricingOptions: false,
  onSelectDuration: false
};

export default AffiliatePromoPopup;
