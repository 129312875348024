/* eslint-disable object-curly-newline */
import queryString from 'query-string';
import {
  loadAvailablePaymentSourcesApi, addPaymentSourceApi, editPaymentSourceApi,
  removePaymentSourceApi
} from '../config/api';
import { doneChangingPaymentSource } from './purchase_details';
import { trackEvent } from './mixpanel';
import Const from '../config/const';
import {
  fromMobileApp, clickSourceOrMobileClickSource, paramOrNul,
  noPaymentMethodsEventData, paymentKindToAnalyticsName, currentPaymentMethodData, toCamelCase
} from '../../common/config/utils';
import { trackPageVisit, aditionalPurchaseProps } from './analytics';
import { refreshReducersFromResponse } from '../config/api_functions';

export const startEditingPaymentSource = (id) => ({
  type: Const.paymentSources.edit,
  id
});

export const managePaymentSource = (id) => ({
  type: Const.paymentSources.manage,
  id
});

export const doneManagingPaymentSource = () => managePaymentSource(null);
// export const doneEditingPaymentSource = () => startEditingPaymentSource(null);
export const doneAddCard = () => ({  type: Const.paymentSources.doneAddCard });

export const loadAvailable = (forceReload = false) => (dispatch, getState) => {
  if (!forceReload) {
    const { existing } = getState().paymentSources;
    if (existing) {
      // at least not null
      return;
    }
  }
  dispatch({ type: Const.paymentSources.load });
  loadAvailablePaymentSourcesApi().then((sources) => {
    dispatch({ type: Const.paymentSources.loaded, ...sources });
  }).catch(() => {
    dispatch({ type: Const.paymentSources.done });
    // alert('Cannot load payment sources');
  });
};

export const trackPaymentMethodRemovedEvent = () => (dispatch) => {
  const eventName = 'payment method removed';
  const { getState } = window.store;
  const { paymentSources } = getState();
  const { current, managingSource, existing } = paymentSources;
  const paymentMethodRemovedParams = {
    'deleted payment method': paymentKindToAnalyticsName(managingSource.kind),
    'default payment method': paymentKindToAnalyticsName(current.kind),
    'num of payment methods': existing.length,
    'default payment method removed': managingSource.isDefault,
    ...aditionalPurchaseProps()
  };
  dispatch(trackEvent(eventName, paymentMethodRemovedParams));
};

export const removeSource = (id) => (dispatch) => {
  dispatch({ type: Const.paymentSources.load });
  removePaymentSourceApi(id).then((sources) => {
    dispatch(trackPaymentMethodRemovedEvent());
    dispatch({ type: Const.paymentSources.loaded, ...sources });
  }).catch(() => {
    dispatch({ type: Const.paymentSources.done });
    // alert('Cannot load payment sources');
  });
};

export const trackStartAddingCreditCard = (clickSource) => (dispatch, getState) => {
  const { paymentOptions: { options } } = getState();
  trackPageVisit('add new card draw');
  const properties = {
    ...noPaymentMethodsEventData(options),
    ...currentPaymentMethodData(),
    'purchase screen' : clickSourceOrMobileClickSource(clickSource),
    ...aditionalPurchaseProps()
  };
  dispatch(trackEvent('add credit card started', properties));
};

export const trackCreditCardAdded = (params, clickSource) => (dispatch, getState) => {
  const { paymentOptions: { options } } = getState();
  const eventName = 'credit card added';
  const eventProperties = {
    'purchase screen' : clickSourceOrMobileClickSource(clickSource),
    ...params,
    ...currentPaymentMethodData(),
    ...noPaymentMethodsEventData(options),
    ...aditionalPurchaseProps()
  };
  dispatch(trackEvent(eventName, eventProperties));
};

export const trackAddCreditCardFailed = (params) => (dispatch) => {
  const {
    scheme, failedReason, errorCode, error, errorName
  } = params;
  const properties = {
    'cc type': scheme ? (scheme).toLowerCase() : undefined,
    ...paramOrNul('failed reason error code', errorCode),
    ...paramOrNul('error message', error),
    ...paramOrNul('failed reason description', errorName),
    ...paramOrNul('failed reason', failedReason),
    ...aditionalPurchaseProps()
  };
  dispatch(trackEvent('add credit card failed', properties));
};

export const trackPaymentMethodChanged = (properties, clickSource) => (dispatch) => {
  let purchaseScreen = clickSourceOrMobileClickSource(clickSource);
  if (window.location.pathname.includes('/payment_source')) purchaseScreen = 'payment methods';
  dispatch(trackEvent('payment method changed', {
    ...properties,
    ...currentPaymentMethodData(),
    'purchase screen' : purchaseScreen,
    ...aditionalPurchaseProps()
  }));
};

export const addSource = (kind) => (dispatch) => {
  dispatch({ type: Const.paymentSources.load });
  addPaymentSourceApi(kind)
    .then(({ source: { id, gatewayName }, options }) => {
      dispatch(loadAvailable(true));
      if (gatewayName === 'pp') {
        dispatch({ type: Const.paymentOptions.loaded, options, skipNextLoad: true });
        dispatch(doneChangingPaymentSource());
        return;
      }
      dispatch(startEditingPaymentSource(id));
    })
    .catch(() => {
      dispatch({ type: Const.paymentSources.done });
      // alert('Cannot create a payment source');
    });
};

export const clearAddingCardError = () => ({
  type: Const.paymentSources.clearAddingCardError
});

const setAddingCardError = (error) => (dispatch) => {
  dispatch({
    type: Const.paymentSources.addingCardError,
    error
  });
};

export const editCardIfSecurityReasons = (id, kind) => (dispatch) => {
  const params = { generate_tokens: true };
  editPaymentSourceApi({ id, params, type: 'editPaymentSourceApi' }).then(() => {
    dispatch(addSource(kind));
  });
};

export const editPaymentSource = (id) => (dispatch) => {
  // dispatch({ type: Const.editPaymentSources.load, id });
  // dispatch({ type: Const.paymentSources.load });
  const params = { generate_tokens: true };
  editPaymentSourceApi({ id, params, type: 'editPaymentSourceApi' }).then((config) => {
    dispatch({ type: Const.paymentSources.addingCardData, config });
  }).catch(() => {
    dispatch({ type: Const.paymentSources.addingCardData });
  });
};

export const updatePaymentSource = (paymentSourceParams) => (dispatch) => {
  const { id, context, callback } = paymentSourceParams;
  const params = { context };
  editPaymentSourceApi({ id, params, type: 'updatePaymentSourceApi' })
    .then(() => {
      if (callback) callback();
      dispatch(loadAvailable(true));
      dispatch(doneAddCard());
      dispatch(doneChangingPaymentSource());
    })
    .catch((error) => {
      const  { cardData: { scheme } } = context;
      try {
        error.json().then(errorJson => {
          dispatch(setAddingCardError(errorJson.error || errorJson.error_name || error.statusText));
          const { errorCode, errorName, error: errorMessage } = errorJson;
          const addCreditCardFailedParams = { scheme, failedReason: 'api error', errorCode, error: errorMessage, errorName };

          refreshReducersFromResponse(toCamelCase(errorJson)); // Need for update user

          dispatch(trackAddCreditCardFailed(addCreditCardFailedParams));
        });
      } catch (err2) {
        const addCreditCardFailedParams = { scheme, failedReason: 'api error', errorCode: error.status, error: error.statusText };
        dispatch(trackAddCreditCardFailed(addCreditCardFailedParams));
        dispatch(setAddingCardError(error.statusText));
      }
    });
};

const doneMakeDefault = (history, location) => (dispatch) => {
  if (fromMobileApp) {
    history({ search: queryString.stringify({ state: 'completed', ...(queryString.parse(location.search)) }) });
  }
  dispatch(doneChangingPaymentSource());
};

export const onMakeDefault = (history, location, makeDefParams) => (dispatch, getState) => {
  const {
    kind, type, clickSource, id
  } = makeDefParams;
  const { paymentSources: { current } } = getState();
  if (current && id === current.id) {
    dispatch(doneMakeDefault(history, location));
    return;
  }
  dispatch({ type: Const.paymentSources.load });
  const params = { set_default: true };
  editPaymentSourceApi({ id, params, type: 'makeDefaultSourceApi' })
    .then(({ sources, options }) => {
      const ccType = kind === 'cc' ? { 'cc type': type } : null;
      dispatch(trackPaymentMethodChanged({
        'payment method' : paymentKindToAnalyticsName(kind),
        'payment id': id,
        ...ccType
      }, clickSource));
      dispatch({ type: Const.paymentOptions.loaded, options, skipNextLoad: true });
      dispatch({ type: Const.paymentSources.loaded, ...sources });
      dispatch(doneMakeDefault(history, location));
    }).catch(() => { dispatch({ type: Const.paymentSources.done }); });
};

export const cleanUp = () => (dispatch) => {
  dispatch(doneAddCard());
  dispatch(doneManagingPaymentSource());
};
