import { connect } from 'react-redux';
import PromotionLabel from './promotion_label';
import withPGTranslation from '../../../../config/withPGTranslation';
import { loadHomePage } from '../../../../actions/home_page';
import { updatePromoBannerVisibility } from '../../../../actions/application';

const mapStateToProps = ({ homePage, application }) => ({
  ...homePage.salesBanner.content,
  promoBannerVisible: application.promoBannerVisible
});

export default connect(
  mapStateToProps,
  {
    loadHomePage,
    updatePromoBannerVisibility
  }
)(withPGTranslation(PromotionLabel));
