import React from 'react';
import queryString from 'query-string';
import classNames from 'classnames';
import { isKA, isWebView, toCamelCase } from '../../../../../common/config/utils';
import Const from '../../../../config/const';
import { isAdvisorBusy, getSectionForEvent, getClickSourceForEvent } from '../../../../config/util';
import classes from './classes.module.scss';

import { modeImages } from './style_config';

export const getAltMessage = ({ mode }) => {
  const altMessageForModes = { chat: 'Live chat', voice: 'Voice call', video: 'Video call' };
  return altMessageForModes[mode];
};

export const getModeTitle = ({ mode, name, t }) => {
  if (mode === 'chat') return t('advisor_profile.chat_mode_name');
  return name;
};

const triggerLogin = (args) => {
  const {
    completion, authPendingAction, displayRegister, mode
  } = args;
  authPendingAction(() => { completion(args); });
  let signUpFlow = '';
  switch (mode) {
    case 'voice': signUpFlow = 'voice profile'; break;
    case 'video': signUpFlow = 'video profile';  break;
    default: signUpFlow = 'chat profile'; break;
  }
  displayRegister({ shouldDisplay: true, signUpFlow });
};

const getStartChatClickSource = (location) => {
  const startChatClickSource = location.state && location.state.startChatClickSource ? location.state.startChatClickSource : 'direct';
  return startChatClickSource;
};

export const onStartChat = ({
  id, name, profilePictureUrl, price,
  location, chatInit, startChat, mode, displayRegister, authPendingAction, requestParams
}) => {
  const { section } = location.state || {};
  const homepageSection = section;
  const startChatAction = () => {
    chatInit(id, name, profilePictureUrl, price, getStartChatClickSource(location), requestParams);
    startChat(
      id,
      parseFloat(price),
      profilePictureUrl,
      name,
      getStartChatClickSource(location),
      homepageSection,
      requestParams
    );
  };
  if (!window.currentUserId) {
    triggerLogin({
      name,
      profilePictureUrl,
      price,
      id,
      mode,
      displayRegister,
      authPendingAction,
      completion: startChatAction,
      requestParams
    });
    return;
  }
  startChatAction();
};

export const onStartVoip = ({
  id, name, profilePictureUrl, price,
  location, makeCallOrShowPopup, mode, displayRegister, authPendingAction, requestParams
}) => {
  const { section } = location.state || {};
  const homepageSection = section;
  const startVoipAction = () => {
    makeCallOrShowPopup({
      id, ppm: parseFloat(price), profilePictureUrl, name, startChatClickSource: getStartChatClickSource(location),
      homepageSection, voipType: mode === 'voice' ? Const.chatType.voice : Const.chatType.video, requestParams
    });
  };
  if (!window.currentUserId) {
    triggerLogin({
      name,
      profilePictureUrl,
      price,
      id,
      mode,
      displayRegister,
      authPendingAction,
      completion: startVoipAction,
      requestParams
    });
    return;
  }
  startVoipAction();
};

export const getImageSrc = ({ mode, modeState, liveModes }) => {
  if (isWebView() && (mode === 'voice' || mode === 'video')) {
    return modeImages[`${ mode }_offline`];
  }
  if (modeState === 'online') return modeImages[`${ mode }_${ modeState }`];
  if (isAdvisorBusy({ liveModes })) return modeImages[`${ mode }_busy`];
  return modeImages[`${ mode }_offline`];
};

export const renderPrice = (salesPrice, price) => (
  <span style={ salesPrice ? { textDecoration: 'line-through' } : {} }>
    {price ? `$${ parseFloat(price).toFixed(2) }/min` : '\n'}
  </span>
);

export const promotionPrice = (salesPrice) => {
  if (!salesPrice) return null;
  return (
    <div className={ classes.salesPriceContainer }>
      {`$${ parseFloat(salesPrice).toFixed(2) }/min`}
    </div>
  );
};

export const trackProfileChatButtonAction = ({
  price, id, mode, location, trackProfileChatButton
}) => {
  const params = {
    ppm: parseFloat(price),
    advisorId: id,
    mode,
    ...getClickSourceForEvent({ location }),
    ...getSectionForEvent({ location })
  };
  trackProfileChatButton(params);
};

export const getAdvisorCTABtnClassName = ({ theme }) => {
  if (isKA()) return classNames(classes[`${ theme }`], classes.fullWidth);
  return classes[`${ theme }`];
};

const getAction = () => {
  const availableAction = { chat_init: 'chat', voice_init: 'voice', video_init: 'video' };
  const { action } = toCamelCase(queryString.parse(window.location.search));
  return availableAction[action];
};

export const makeActionIfNeed = ({
  mode, advisorCTAOnClick, location, history
}) => {
  const action = getAction();
  if (action === mode) {
    const search = toCamelCase(queryString.parse(location.search));
    delete search.action;
    const updatedSearch = queryString.stringify(search);
    history(`${ location.pathname }?${ updatedSearch }`, {
      search: updatedSearch,
      state: { ...location.state }
    });
    advisorCTAOnClick({ action });
  }
};
