import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { appLinks, socialLinks } from '../../../config/const';
import { fromMobileApp, isKA } from '../../../../common/config/utils';
import  withPGTranslation  from '../../../config/withPGTranslation';
import { appLogo } from '../../../logo/logo';

const fbIcon = require('./img/fb_footer.svg');
const igIcon = require('./img/ig_footer.svg');
const tickTokIcon = require('./img/tiktok_footer.svg');
const twitterIcon = require('./img/twitter_footer.svg');
const ytIcon = require('./img/yt_footer.svg');

function Footer({ t, footerMobileBottomHeight }) {
  const onIconTap = () => {
    window.scrollTo({ left: 0, top:0, behavior: 'smooth' });
  };

  const links = () => (
    <div className={ classes.privacyLinksContainer }>
      <a
        className={ classes.privacyLinks }
        target="_blank"
        href={ appLinks.privacyPolicy }
        rel="noreferrer"
      >
        {t('privacy_policy')}
      </a>
      <div suppressHydrationWarning style={ { width: '10px' } } />
      <a
        className={ classes.privacyLinks }
        href={ appLinks.tos }
        target="_blank"
        rel="noreferrer"
      >
        {t('terms_of_use')}
      </a>
      <div suppressHydrationWarning style={ { width: '10px' } } />
      <a
        className={ classes.privacyLinks }
        href={ appLinks.affiliation }
        target="_blank"
        rel="noreferrer"
      >
        {t('affiliation')}
      </a>
    </div>
  );

  const socialIcon = (iconName, URL) => (
    <a href={ URL } target="_blank" rel="noreferrer" className={ classes.socialImg }>
      <img src={ iconName } alt="" />
    </a>
  );

  const social = () => (
    <div className={ classes.socialContainer }>
      {socialIcon(twitterIcon, socialLinks.x)}
      {socialIcon(fbIcon, socialLinks.fb)}
      {socialIcon(igIcon, socialLinks.ig)}
      {socialIcon(tickTokIcon, socialLinks.tt)}
      {socialIcon(ytIcon, socialLinks.yt)}

    </div>
  );

  const getInc = () => {
    if (isKA()) return 'Kasamba Inc.';
    return 'Barges Technologies, Inc.';
  };

  const renderFooter = () => {
    if (fromMobileApp) return null;
    return (
      <div className={ classes.footerContainer } suppressHydrationWarning>
        <button type="button" className={ classes.footerIconButton } onClick={ onIconTap } suppressHydrationWarning>
          <img src={ appLogo() } alt="" width={ 69 } height={ 69 } suppressHydrationWarning />
        </button>
        <div suppressHydrationWarning style={ { height:'17px' } } />
        <span className={ classes.footerTitle } suppressHydrationWarning>
          {t('logo_text')}
        </span>
        <div suppressHydrationWarning style={ { height:'15px' } } />
        <div suppressHydrationWarning className={ classes.footerText }>
          ©
          <div suppressHydrationWarning>
            {new Date().getFullYear() || '' }
          </div>
          &nbsp;
          <div suppressHydrationWarning>
            { getInc() }
          </div>
        </div>
        {links()}
        {social()}
        <div suppressHydrationWarning className={ classes.footerEmptyContainer } style={ { '--footerMobileBottomHeight': `${ footerMobileBottomHeight }px` } } />
      </div>
    );
  };

  return renderFooter();
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  footerMobileBottomHeight: PropTypes.number
};
Footer.defaultProps = {
  footerMobileBottomHeight: 0
};
export default withPGTranslation(Footer);
