import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AdvisorSection from '../advisors_section';
import { advisorsSectionThemes } from '../../prop_types';
import classes from './classes.module.scss';

function PersonalSection({
  personalSections
}) {
  const [selectedSection, setSelectedSection] = useState(
    (personalSections[Object.keys(personalSections)[0]] || {}).id
  );

  const personalSectionsMenuItemClick = (e) => {
    setSelectedSection(e.target.id);
  };

  const renderPersonalSection = () => (
    <div className={ classes.personalSectionsContainer }>
      <div className={ classes.personalSectionsMenuContainer }>
        <div className={ classes.personalSectionsMenuItemsContainer }>
          { Object.keys(personalSections).map((key) => (
            <div
              key={ personalSections[key].id }
              className={ classes.personalSectionsMenuItemContainer }
            >
              <button
                id={ personalSections[key].id }
                type="button"
                className={
                  selectedSection === personalSections[key].id
                    ? classes.personalSectionsMenuItemSelected
                    : classes.personalSectionsMenuItem
                }
                onClick={ personalSectionsMenuItemClick }
              >
                { personalSections[key].title }
              </button>
              <div className={ classes.smallDivider } />
            </div>
          )) }
        </div>
        <div className={ classes.divider } />
      </div>
      <div className={ classes.personalSectionsAdvosorsContainer }>
        <AdvisorSection
          { ...personalSections[selectedSection] }
          theme={ advisorsSectionThemes.personal }
          personalSection
        />
      </div>
    </div>
  );

  return (
    <>
      { renderPersonalSection() }
    </>
  );
}

PersonalSection.propTypes = {
  personalSections: PropTypes.object,
  t: PropTypes.func.isRequired
};

PersonalSection.defaultProps = {
  personalSections: {}
};

export default PersonalSection;
