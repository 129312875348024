import React from 'react';
import { SectionProps, SectionDefaultProps } from '../prop_types';
import classes from '../classes.module.scss';
import AppLink from '../../routes/app_link';
import { specialityNameOnTwoLine } from '../../../config/util';
import { specialitiesClickSource } from '../../../config/const';
import { isKA } from '../../../../common/config/utils';

function SpecialitiesSection({ content }) {
  const renderSpeciality = (speciality, index) => {
    const {
      backgroundColor, iconUrl, name, textColor, slug, params
    } = speciality;

    const specialityClass = isKA() ? classes.specialityKA : classes.specialityPG;

    return (
      <AppLink
        to={ {
          pathname: slug,
          state: {
            needRefresh: true,
            trackCategoryEvent: true,
            clickSource: specialitiesClickSource.homePage,
            params
          }
        } }
        key={ index }
        className={ `${ classes.speciality } ${ specialityClass }` }
        style={ { backgroundColor, color: textColor, '--itemsCount': content.specialities.length } }
      >
        <img src={ iconUrl } alt="" className={ classes.specialityImg } />
        { specialityNameOnTwoLine(name, classes.specialityName) }
      </AppLink>
    );
  };

  const renderContent = () => {
    if (!(content || {}).specialities) return null;
    return (
      <div
        className={ classes.specialitiesContainer }
      >
        { content.specialities.map((speciality, index) => renderSpeciality(speciality, index)) }
      </div>
    );
  };

  return renderContent();
}

SpecialitiesSection.propTypes = {
  ...SectionProps
};
SpecialitiesSection.defaultProps = {
  ...SectionDefaultProps
};

export default SpecialitiesSection;
