import React from 'react';
import { lazyRetry } from '../../../../common/config/utils';

const SearchResults = React.lazy(() => lazyRetry(() => import('../../search_results'), 'SearchResults'));

// "/love-readings",
// "/angel-insights",
// "/dream-analysis",
// "/astrology-and-horoscopes",
// "/tarot-readings",
// "/psychic-readings",
// "/palm-readings",
// "/oracle-guidance"

export const categoryRoutesPG = {
  '/angel-insights': {
    Component: SearchResults,
    localizedPaths: {
      es: '/claves-de-los-angeles'
    }
  },
  '/astrology-and-horoscopes': {
    Component: SearchResults,
    localizedPaths: {
      es: '/astrologia-y-horoscopo'
    }
  },
  '/dream-analysis': {
    Component: SearchResults,
    localizedPaths: {
      es: '/analisis-de-los-suenios'
    }
  },
  '/love-readings': {
    Component: SearchResults,
    localizedPaths: {
      es: '/lecturas-amorosas'
    }
  },
  '/oracle-guidance': {
    Component: SearchResults,
    localizedPaths: {
      es: '/guia-de-los-oraculos'
    }
  },
  '/palm-readings': {
    Component: SearchResults,
    localizedPaths: {
      es: '/lectura-de-manos'
    }
  },
  '/psychic-readings': {
    Component: SearchResults,
    localizedPaths: {
      es: '/lecturas-psiquicas'
    }
  },
  '/tarot-readings': {
    Component: SearchResults,
    localizedPaths: {
      es: '/lectura-de-tarot'
    }
  }
};
