import { connect } from 'react-redux';
import Const from '../../../config/const';
import SelectChatCredit from './select_chat_credit';
import {
  selectCredit,
  cancelChat,
  loadPricingOptions,
  trackDurationPickExit,
  selectDurationOption
} from '../../../actions/chat';
import {
  cancelVoipChat,
  loadVoipPricingOptions,
  selectVoipCredit,
  trackVoipDurationPickExit,
  selectVoipDurationOption
} from '../../../actions/voip_chat';
import withPGTranslation from '../../../config/withPGTranslation';
import { displayAffiliatePromoPopupAction, clearAffiliatePromoPopup } from '../../../actions/affiliate_promo_popup';

const getAdvisorXfmProgram = ({ advisors, advisorId }) => {
  const advisor = (advisors || {})[parseInt(advisorId, 10)];
  return advisor && advisor.xfmProgram;
};

const mapStateToProps = ({
  chat, voipChat, user: { user }, advisors
}, { isContinue, chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return ({
        ...chat,
        isContinue,
        clientAvailableCredit: user && user.clientAvailableCredit,
        disabledStartChatButton:
          chat.state !== Const.chatStates.appInitial
          && chat.state !== Const.chatStates.paused,
        userMatchUpBonusInfo: user && user.matchUpBonusInfo,
        userShowMatchUpBonusInfo: user && user.showMatchUpBonusInfo,
        isNewUser: chat && chat.selectDurationKind === Const.selectDurationKind.newUser,
        advisorXfmProgram: getAdvisorXfmProgram({ advisors, advisorId: chat.advisorId }),
        userXfmProgram: user && user.xfmProgram,
        loading: chat.loading || (user && user.paymentProcessing),
        selectDurationKind: chat && chat.selectDurationKind
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        ...voipChat,
        isContinue,
        clientAvailableCredit: user && user.clientAvailableCredit,
        disabledStartChatButton:
          voipChat.voipState !== Const.voipChatStates.initial
          && voipChat.voipState !== Const.voipChatStates.paused,
        userMatchUpBonusInfo: user && user.matchUpBonusInfo,
        userShowMatchUpBonusInfo: user && user.showMatchUpBonusInfo,
        isNewUser: voipChat && voipChat.selectDurationKind === Const.selectDurationKind.newUser,
        advisorXfmProgram: getAdvisorXfmProgram({ advisors, advisorId: voipChat.advisorId }),
        userXfmProgram: user && user.xfmProgram,
        loading: voipChat.loading || (user && user.paymentProcessing),
        selectDurationKind: voipChat && voipChat.selectDurationKind
      });
    default:
      return null;
  }
};

const defaultFunctions = (dispatch) => ({
  displayAffiliatePromoPopupAction: (creditMatchingInfo, type, additionParams) => {
    dispatch(displayAffiliatePromoPopupAction(creditMatchingInfo, type, additionParams));
  },
  clearAffiliatePromoPopup: () => dispatch(clearAffiliatePromoPopup())
});

const defaultVoipFunctions = (dispatch) => ({
  selectCredit: (option, time, defaultDurationChanged, isContinue) => dispatch(
    selectVoipCredit(option, time, defaultDurationChanged, isContinue)
  ),
  cancelChat: () => dispatch(cancelVoipChat()),
  trackDurationPickExit: (duration, time, defaultDurationChanged) => dispatch(
    trackVoipDurationPickExit(duration, time, defaultDurationChanged)
  ),
  selectDurationOption: (option, onContinue, isNewUser, index) => dispatch(
    selectVoipDurationOption(option, onContinue, isNewUser, index)
  )
});

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        selectCredit: (option, time, defaultDurationChanged, isContinue) => dispatch(
          selectCredit(option, time, defaultDurationChanged, isContinue)
        ),
        cancelChat: () => dispatch(cancelChat()),
        loadPricingOptions: (advisorId) => dispatch(loadPricingOptions('chat', advisorId)),
        trackDurationPickExit: (option, time, defaultDurationChanged) => dispatch(
          trackDurationPickExit(option, time, defaultDurationChanged)
        ),
        selectDurationOption: (option, isContinue, isNewUser, index) => dispatch(
          selectDurationOption(option, isContinue, isNewUser, index)
        ),
        ...defaultFunctions(dispatch)
      });
    case Const.chatType.video:
      return ({
        loadPricingOptions: (advisorId) => dispatch(loadVoipPricingOptions('video', advisorId)),
        ...defaultVoipFunctions(dispatch),
        ...defaultFunctions(dispatch)
      });
    case Const.chatType.voice:
      return ({
        loadPricingOptions: (advisorId) => dispatch(loadVoipPricingOptions('voice', advisorId)),
        ...defaultVoipFunctions(dispatch),
        ...defaultFunctions(dispatch)
      });
    default:
      return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPGTranslation(SelectChatCredit));
