/* eslint-disable import/no-cycle */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../menu_item';
import { renderSideMenuIcon, requireContext, isMenuItemActive } from '../side_menu';
import { specialitiesClickSource } from '../../../config/const';
import { localizedCategories } from '../../../config/app_routes';
import classes from './classes.module.scss';
import { isKA, getIcon } from '../../../../common/config/utils';

const specialitiesKa = [
  {
    name: 'Psychic Readings',
    slug: '/psychic-reading/',
    subSpecialities: [
      { name: 'Aura Readings', slug: '/aura-reading/' },
      { name: 'Crystal Readings', slug: '/crystal-reading/' },
      { name: 'Pet Psychics', slug: '/pet-psychics/' },
      { name: 'Psychic Mediums', slug: '/mediums-reaching-beyond/' },
      { name: 'Rune Casting', slug: '/rune-casting/' }
    ]
  },
  {
    name: 'Love Psychics',
    slug:'/love-relationships/',
    subSpecialities: []
  },
  {
    name: 'Tarot Readings',
    slug: '/tarot-reading/',
    subSpecialities : [
      { name: 'Angel Card Reading', slug: '/angel-card-reading/' }
    ]
  },
  {
    name: 'Fortune Telling',
    slug: '/fortune-telling/',
    subSpecialities  : []
  },
  {
    name: 'Dream Analysis',
    slug: '/dream-analysis/',
    subSpecialities : []
  },
  {
    name: 'Astrology Readings',
    slug: '/astrology/',
    subSpecialities : [
      { name: 'Chinese Astrology', slug: '/chinese-astrology/' },
      { name: 'Vedic Astrology', slug: '/vedic-astrology/' }
    ]
  },
  {
    name: 'Career Forecasts',
    slug: '/career-forecasts/',
    subSpecialities  : [
      { name: 'Numerology', slug: '/numerology/' }
    ]
  },
  {
    name: 'More Specialties',
    slug: null,
    subSpecialities  : [
      { name: 'Spell Casting', slug: '/spell-casting/' },
      { name: 'Palm Readings', slug: '/palm-reading/' },
      { name: 'Graphology', slug: '/graphology/' },
      { name: 'Psíquicos en Español', slug: '/espa-ol-ps-quicos/' },
      { name: 'Paranormal', slug: '/paranormal/' },
      { name: 'Ghosts', slug: '/ghosts/' },
      { name: 'Past Life Readings', slug: '/past-life-reading/' },
      { name: 'New Age Spirituality', slug: '/new-age-spirituality/' },
      { name: 'Fertility', slug: '/fertility/' }
    ]
  }
];

const sideMenuArrow = (iconToUse) => (
  <img
    src={ getIcon({ iconName: iconToUse, requireContext }) }
    alt=""
    className={ isKA() ? classes.specialitiesArrowKa : classes.specialitiesArrow }
  />
);

const renderSideMenuSpecialitiesArrow = (specialitiesShow) => {
  const iconName = isKA() ? 'specialities_ka_arrow' : 'specialities_arrow';
  const iconToUse = specialitiesShow ? `${ iconName }_top` : `${ iconName }_down`;
  return (
    <div className={ isKA() ? classes.specialitiesArrowBtn : null }>
      {sideMenuArrow(iconToUse)}
    </div>
  );
};

const getSpecialitiesStyle = () => {
  if (isKA()) return { width: '24px', height: '24px' };
  return {};
};

const getNeedChekActive = () => {
  if (isKA()) return false;
  return true;
};

const getKaRouteState = (specialities, slug) => (specialities?.find(s => s.slug === slug) ? {
  needRefresh: true,
  trackCategoryEvent: true,
  clickSource: specialitiesClickSource.menu,
  params: specialities.find(s => s.slug === slug).params
} : {
  trackCategoryEvent: true,
  clickSource: specialitiesClickSource.menu
});

const setSubSpecialitiesShowDefault = () => {
  const subSpecialitiesSlugs = specialitiesKa.map(s => s.subSpecialities).flat().map(r => r.slug);
  if (subSpecialitiesSlugs.includes(window.location.pathname)) {
    return specialitiesKa.find(s => s.subSpecialities.find(subs => subs.slug?.includes(window.location.pathname)))?.subSpecialities;
  }
  return [];
};

const specialityOnClick = (e, speciality, subSpecialitiesShow, setSubSpecialitiesShow) => {
  e.preventDefault();
  e.stopPropagation();
  if (subSpecialitiesShow.includes(...speciality)) {
    setSubSpecialitiesShow(subSpecialitiesShow.filter((s) => !speciality.includes(s)));
  } else setSubSpecialitiesShow([...subSpecialitiesShow, ...speciality]);
};

const renderSideMenuSpecialitiesArrowBtn = (s, subSpecialitiesShow, userLoggedIn, setSubSpecialitiesShow) => {
  if (s.subSpecialities.length <= 0) return null;
  const iconToUse = subSpecialitiesShow.includes(...s.subSpecialities) ? 'specialities_ka_arrow_top' : 'specialities_ka_arrow_down';
  return (
    <button
      type="button"
      className={ userLoggedIn ? classes.specialitiesArrowBtnLogedIn : classes.specialitiesArrowBtn }
      onClick={ e => specialityOnClick(e, s.subSpecialities, subSpecialitiesShow, setSubSpecialitiesShow) }
    >
      {sideMenuArrow(iconToUse)}
    </button>
  );
};

const renderSubSpecialities = (s, subSpecialitiesShow) => (subSpecialitiesShow.includes(...s.subSpecialities)
  ? (
    <div className={ classes.subSpecialitiesContainer }>
      { s.subSpecialities.map((subs) => (
        <MenuItem
          asHref
          key={ subs.name }
          title={ { text: subs.name, active: isMenuItemActive(subs.slug) } }
          linkTo={ subs.slug }
          openInNewTab={ false }
        />
      ))}
    </div>
  ) : null);

const renderKaSpecialities = (specialities, subSpecialitiesShow, userLoggedIn, setSubSpecialitiesShow) => (
  specialitiesKa.map((s, index) => (
    <Fragment key={ `${ s.name }_${ index }` }>
      <MenuItem
        key={ s.name }
        title={ { text: s.name, active: s.slug ? isMenuItemActive(s.slug) : false } }
        onClick={ s.slug ? null : (e) => specialityOnClick(e, s.subSpecialities, subSpecialitiesShow, setSubSpecialitiesShow) }
        linkTo={ {
          pathname: s.slug,
          state: getKaRouteState(specialities, s.slug)
        } }
        extraNode={ renderSideMenuSpecialitiesArrowBtn(s, subSpecialitiesShow, userLoggedIn, setSubSpecialitiesShow) }
      />
      { renderSubSpecialities(s, subSpecialitiesShow) }
    </Fragment>
  ))
);

const renderSpecialities = (specialities, subSpecialitiesShow, userLoggedIn, setSubSpecialitiesShow) => {
  if (isKA()) return renderKaSpecialities(specialities, subSpecialitiesShow, userLoggedIn, setSubSpecialitiesShow);
  if (!specialities) return null;
  return (
    specialities.map((s) => (
      <MenuItem
        key={ s.id + s.name }
        title={ { text: s.name, active: isMenuItemActive(s.slug) } }
        icon={ renderSideMenuIcon({
          iconName: s.iconName, path: s.slug, needChekActive: getNeedChekActive(), style: getSpecialitiesStyle()
        }) }
        linkTo={ {
          pathname: s.slug,
          state: {
            needRefresh: true,
            trackCategoryEvent: true,
            clickSource: specialitiesClickSource.menu,
            params: s.params
          }
        } }
      />
    ))
  );
};

function SpecialitiesMenuItem({ specialities, t }) {
  const [specialitiesShow, setSpecialitiesShow] = useState(
    localizedCategories.includes(window.location.pathname)
  );
  const [subSpecialitiesShow, setSubSpecialitiesShow] = useState(setSubSpecialitiesShowDefault());

  const userLoggedIn = window.currentUserId;

  const showSpecialities = () => {
    setSpecialitiesShow(!specialitiesShow);
  };

  const renderSpecialitiesMenuItem = () => {
    if (userLoggedIn) {
      return (
        <>
          <MenuItem
            title={ { text: t('sideMenu.specialities') } }
            icon={ renderSideMenuIcon({ iconName: 'specialities_menu_icon', path: ' ' }) }
            onClick={ showSpecialities }
            extraNode={ renderSideMenuSpecialitiesArrow(specialitiesShow) }
          />
          { specialitiesShow ? (
            <div className={ classes.specialitiesContainer }>
              {renderSpecialities(specialities, subSpecialitiesShow, userLoggedIn, setSubSpecialitiesShow)}
            </div>
          ) : null }
        </>
      );
    }
    return renderSpecialities(specialities, subSpecialitiesShow, userLoggedIn, setSubSpecialitiesShow);
  };

  return renderSpecialitiesMenuItem();
}

SpecialitiesMenuItem.propTypes = {
  t: PropTypes.func.isRequired,
  specialities: PropTypes.array
};

SpecialitiesMenuItem.defaultProps = {
  specialities: null
};

export default SpecialitiesMenuItem;
