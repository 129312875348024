import {
  loadPaymentOptionsApi
} from '../config/api';
import Const from '../config/const';
import { trackEvent } from './mixpanel';
import {
  clickSourceOrMobileClickSource, paramOrNul, noPaymentMethodsEventData, withCaptcha
} from '../../common/config/utils';
import { log } from '../config/app_logger';

const kindToAnalyticsName = (kind) => {
  if (kind === 'cc') {
    return 'credit card';
  }
  if (kind === 'pp') {
    return 'paypal';
  }
  return '';
};

const { reportAddFundsScreen } = window.config;

export const trackMPEventAdditionalFundsRequired = (
  additionalFundsParams
) => (dispatch, getState) => {
  const {
    options, clickSource, credit, bonus, initialChargedPropName,
    initialDurationPropName, payAsYouGo, flow
  } = additionalFundsParams;
  const {
    paymentSources: { current }, chat: { initialCharged, initialDuration },
    voipChat: { initialVoipCharged, initialVoipDuration }
  } = getState();
  if (reportAddFundsScreen === 'no') return;
  if (reportAddFundsScreen === 'new' && !current) return;

  const eventName = 'add funds screen';
  const noPaymentMethodsData = noPaymentMethodsEventData(options);

  let params = {
    'purchase screen' : clickSourceOrMobileClickSource(clickSource),
    ...paramOrNul('credit', !payAsYouGo ? (credit + bonus) : null),
    ...paramOrNul('base price', !payAsYouGo ? credit : null),
    ...paramOrNul(initialDurationPropName, !payAsYouGo ? (initialCharged || initialVoipCharged) : null),
    ...paramOrNul(initialChargedPropName, !payAsYouGo ? (initialDuration || initialVoipDuration) : null),
    'pay as you go used': !!payAsYouGo,
    flow
  };
  if (noPaymentMethodsData) {
    params = {
      ...params,
      ...noPaymentMethodsData
    };
  } else {
    options.forEach((o) => {
      const { kind, context: { type }, id } = o;
      params = {
        ...params,
        'payment method': kindToAnalyticsName(kind),
        'payment id': id,
        ...paramOrNul('error', o.errorText),
        ...paramOrNul('payment gateway', o.gatewayName)
      };
      if (type) {
        params['cc type'] = type;
      }
    });
  }
  dispatch(trackEvent(eventName, params));
};

export const loadPaymentOptionsOnClose = () => (dispatch, getState) => {
  const { current, existing }  = getState().paymentSources;
  if (!current && existing?.length > 0) {
    dispatch({ type: Const.paymentOptions.load });
    withCaptcha('checkout', (token) => loadPaymentOptionsApi(token).then(newOptions => {
      dispatch({ type: Const.paymentOptions.loaded, options: newOptions });
    }).catch((error) => log('Error loadPaymentOptionsApi', error)));
  }
};

export const loadPaymentOptions = () => (dispatch, getState) => {
  const { skipNextLoad, options } = getState().paymentOptions;
  if (skipNextLoad) {
    dispatch({ type: Const.paymentOptions.loaded, options });
    return;
  }
  dispatch({ type: Const.paymentOptions.load });
  withCaptcha('checkout', (token) => loadPaymentOptionsApi(token).then(newOptions => {
    dispatch({ type: Const.paymentOptions.loaded, options: newOptions });
  }).catch((error) => log('Error loadPaymentOptionsApi', error)));
};

export const reset = () => (dispatch) => {
  dispatch({ type: Const.paymentOptions.reset });
};
