import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import Registration from './registration';
import {
  logIn,
  register,
  clearError,
  authSocial,
  displayRegister,
  changeScreen,
  navigationPop,
  authClearPendingAction,
  authErrorActionCreator,
  gdprSwitcherValueChanged,
  shouldShowGDPRConsent,
  updateCurrentScreen
} from '../../actions/auth';
import { loadHomePage } from '../../actions/home_page';
import { clearAffiliatePromoPopup } from '../../actions/affiliate_promo_popup';

const mapStateToProps = ({ auth, user: { user } }) => ({
  ...auth,
  userMatchUpBonusInfo: user && user.matchUpBonusInfo,
  userShowMatchUpBonusInfo: user && user.showMatchUpBonusInfo
});

export default connect(
  mapStateToProps,
  {
    logIn,
    register,
    clearError,
    authSocial,
    displayRegister,
    changeScreen,
    navigationPop,
    loadHomePage,
    clearAffiliatePromoPopup,
    authClearPendingAction,
    authErrorActionCreator,
    gdprSwitcherValueChanged,
    shouldShowGDPRConsent,
    updateCurrentScreen
  }
)(withPGTranslation(Registration));
