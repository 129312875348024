import { connect } from 'react-redux';
import PurchaseDetails from './purchase_details';
import { purchaseSuccess, purchaseOnBack } from '../../../actions/chat';
import { voipPurchaseSuccess, voipPurchaseOnBack, voipPurchaseSuccessOnContinue } from '../../../actions/voip_chat';
import { purchaseSuccess as sideMenuPurcahseSuccess } from '../../../actions/funds';
import {
  purchaseSuccess as affiliatePromoPurcahseSuccess,
  hideAffiliatePromoPopup,
  noThanksLinkClickedAction
} from '../../../actions/affiliate_promo_popup';
import { doneChangingPaymentSource, changingPaymentSource } from '../../../actions/purchase_details';
import {
  loadPaymentOptions, trackMPEventAdditionalFundsRequired, reset, loadPaymentOptionsOnClose
} from '../../../actions/payment_options';
import {
  setChatPopupRightItem,
  clearChatPopupRightItem,
  updateChatPopupBackAction,
  cleanUpdatedBackAction
} from '../../../actions/general_popup';
import {
  loadAvailable, addSource, startEditingPaymentSource,
  managePaymentSource, onMakeDefault, cleanUp, removeSource,
  doneManagingPaymentSource, trackPaymentMethodChanged,
  editPaymentSource, updatePaymentSource, trackStartAddingCreditCard,
  trackCreditCardAdded, trackAddCreditCardFailed, clearAddingCardError,
  doneAddCard, editCardIfSecurityReasons
} from '../../../actions/payment_sources';
import { trackPaymentSourcesEvent } from '../../../actions/analytics';
import withTranslation from '../../../config/withPGTranslation';
import Const from '../../../config/const';

const mapStateToProps = ({
  chat,
  voipChat,
  user: { user, userMPIdentify },
  user: { loading },
  purchaseDetails,
  paymentOptions,
  paymentSources,
  creditCardValidateProcessing,
  affiliatePromoPopup: { onCloseIconClick }
}, { chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return ({
        ...chat,
        ...user,
        purchaseDetails,
        options: paymentOptions.options,
        paymentSources,
        loadingSources: paymentSources.loading || loading || creditCardValidateProcessing.validating,
        loadingOptions: paymentOptions.loading,
        userMPIdentify,
        creditCardValidateError: creditCardValidateProcessing.error,
        affiliatePromoPopupOnCloseIconClick: onCloseIconClick
      });
    case Const.chatType.voice:
    case Const.chatType.video: {
      return ({
        ...voipChat,
        ...user,
        purchaseDetails,
        options: paymentOptions.options,
        paymentSources,
        loadingSources: paymentSources.loading || loading || creditCardValidateProcessing.validating,
        loadingOptions: paymentOptions.loading,
        userMPIdentify,
        creditCardValidateError: creditCardValidateProcessing.error,
        affiliatePromoPopupOnCloseIconClick: onCloseIconClick
      });
    }
    default:
      return null;
  }
};

const defaultFunctions = (dispatch, ownProps) => ({
  affiliatePromoPurcahseSuccess: () => {
    dispatch(affiliatePromoPurcahseSuccess(ownProps.chatType, ownProps.needUpdatePricingOptions));
  },
  updateChatPopupBackAction: (action) => dispatch(updateChatPopupBackAction(action)),
  cleanUpdatedBackAction: () => dispatch(cleanUpdatedBackAction()),
  doneChangingPaymentSource: () => dispatch(doneChangingPaymentSource()),
  trackMPEventAdditionalFundsRequired:
  (additionalFundsParams) => dispatch(trackMPEventAdditionalFundsRequired(additionalFundsParams)),
  loadAvailable: (forceReload) => dispatch(loadAvailable(forceReload)),
  addSource: (kind) => dispatch(addSource(kind)),
  startEditingPaymentSource: (id) => dispatch(startEditingPaymentSource(id)),
  managePaymentSource: (id) => dispatch(managePaymentSource(id)),
  trackPaymentSourcesEvent: (clickSource) => dispatch(trackPaymentSourcesEvent(clickSource)),
  onMakeDefault: (history, location, makeDefParams) => dispatch(
    onMakeDefault(history, location, makeDefParams)
  ),
  cleanUp: () => dispatch(cleanUp()),
  setChatPopupRightItem: (item) => dispatch(setChatPopupRightItem(item)),
  clearChatPopupRightItem: () => dispatch(clearChatPopupRightItem()),
  removeSource: (id) => dispatch(removeSource(id)),
  doneManagingPaymentSource: () => dispatch(doneManagingPaymentSource()),
  trackPaymentMethodChanged:
  (properties, clickSourc) => dispatch(trackPaymentMethodChanged(properties, clickSourc)),
  editPaymentSource: (id) => dispatch(editPaymentSource(id)),
  updatePaymentSource: (paymentSourceParams) => dispatch(updatePaymentSource(paymentSourceParams)),
  trackStartAddingCreditCard: (clickSource) => dispatch(trackStartAddingCreditCard(clickSource)),
  doneAddCard: () => dispatch(doneAddCard()),
  trackCreditCardAdded:
  (params, clickSource) => dispatch(trackCreditCardAdded(params, clickSource)),
  trackAddCreditCardFailed: (params) => dispatch(trackAddCreditCardFailed(params)),
  clearAddingCardError: () => dispatch(clearAddingCardError()),
  loadPaymentOptions: () => dispatch(loadPaymentOptions()),
  loadPaymentOptionsOnClose: () => dispatch(loadPaymentOptionsOnClose()),
  changingPaymentSource: (changing) => dispatch(changingPaymentSource(changing)),
  reset: () => dispatch(reset()),
  hideAffiliatePromoPopup: () => dispatch(hideAffiliatePromoPopup()),
  noThanksLinkClickedAction: () => dispatch(noThanksLinkClickedAction()),
  editCardIfSecurityReasons: (id, kind) => dispatch(editCardIfSecurityReasons(id, kind))
});

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        purchaseSuccess: () => dispatch(purchaseSuccess()),
        purchaseOnBack: () => dispatch(purchaseOnBack()),
        sideMenuPurcahseSuccess: () => dispatch(sideMenuPurcahseSuccess()),
        ...defaultFunctions(dispatch, ownProps)
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      const voipPurchaseSuccessAction = ownProps.isContinue ? voipPurchaseSuccessOnContinue() : voipPurchaseSuccess(); //eslint-disable-line
      return ({
        purchaseSuccess: () => dispatch(voipPurchaseSuccessAction),
        purchaseOnBack: () => dispatch(voipPurchaseOnBack()),
        sideMenuPurcahseSuccess: () => dispatch(sideMenuPurcahseSuccess()),
        ...defaultFunctions(dispatch, ownProps)
      });
    default:
      return null;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(PurchaseDetails));
