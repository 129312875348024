import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import AppLink from '../../../routes/app_link';
import { SectionProps, SectionDefaultProps } from '../../prop_types';
import Rating from '../../../advisor_avatar/rating/rating';
import classes from './classes.module.scss';
import AdvisorAvatar, { advisorThemes } from '../../../advisor_avatar';
import AdvisorModes from '../advisor_modes/advisor_modes';
import ReadingSinceAndCountItem, { readingSinceAndCountItemThemes } from '../../../advisor/nested_views/readingSinceAndCountItem';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { renderStartCallButton } from '../advisor_preview/advisor_preview';
import { isKA } from '../../../../../common/config/utils';

const renderStartCallButtonContainer = (advisor, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, location, analytics, handleLinkClick, t) => (isKA() ? (
  <div className={ classes.previewAdvisorCTAContainer }>
    {renderStartCallButton(advisor, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, location, analytics, handleLinkClick, t)}
  </div>
) : null);

const handleLinkClickAction = ({
  e, loading, sectionSlug, updateNavigationStack
}) => {
  if (loading) {
    e.preventDefault();
    return;
  }
  if (sectionSlug) { updateNavigationStack({ dataKey: sectionSlug }); }
};

const getObjectValue = (object) => object || {};

function StaffPickSection({
  iconUrl, title, analytics, content, t, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, onAnalyticsClick, loading, sectionSlug, updateNavigationStack
}) {
  const location = useLocation();
  const { advisor } = getObjectValue(content);
  const {
    liveModeMinPrice, nickname, serviceTitle, rating, staffPickText, readingsCount, yearJoined,
    liveModes, liveModeMinSalesPrice, slug, isNewAdvisor
  } = getObjectValue(advisor);

  const advisorHeader = () => (
    <div className={ classes.advisorInfoHeader }>
      <div className={ classes.advisorTitle }>
        { nickname }
        <div className={ classes.advisorSubtitle }>
          {serviceTitle}
        </div>
      </div>
      <Rating rating={ rating } isNewAdvisor={ isNewAdvisor } />
    </div>
  );

  const onDescriptionExpand = (e) => {
    e.preventDefault();
  };

  const staffPickDescription = () => (
    <div className={ classes.staffPickDescription }>
      <ReadingSinceAndCountItem
        readingsCount={ readingsCount }
        yearJoined={ yearJoined }
        theme={ readingSinceAndCountItemThemes.staffPickMobile }
      />
      <ExpandedText
        className={ classes.serviceTitleContainer }
        ellipsis
        limit={ 4 }
        readMoreKind="limitTextByRows"
        readMoreClick={ onDescriptionExpand }
        t={ t }
      >
        { staffPickText }
      </ExpandedText>
    </div>
  );

  const handleLinkClick = (e) => {
    if (onAnalyticsClick) { onAnalyticsClick(); }
    handleLinkClickAction({
      e, loading, sectionSlug, updateNavigationStack
    });
  };

  const footer = () => (
    <div
      className={ classes.footer }
    >
      <AdvisorModes
        liveModeMinPrice={ liveModeMinPrice }
        liveModes={ liveModes }
        liveModeMinSalesPrice={ liveModeMinSalesPrice }
        text={ t('home_page.from') }
        staffPick
      />
      <ReadingSinceAndCountItem
        readingsCount={ readingsCount }
        yearJoined={ yearJoined }
        theme={ readingSinceAndCountItemThemes.staffPick }
      />
      {renderStartCallButtonContainer(advisor, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, location, analytics, handleLinkClick, t)}
    </div>
  );

  const header = () => (
    <div className={ classes.sectionHeader }>
      <img src={ iconUrl } alt="" className={ classes.sectionIcon } />
      <div className={ classes.sectionHeaderSeparator } />
      <div className={ classes.headerTitle }>{title || 'In the spotlight'}</div>
    </div>
  );

  const renderContent = () => {
    if (!advisor) return null;
    return (
      <div className={ classes.mainContentContainer }>
        {header()}
        <AppLink to={ { pathname: slug, state: { ...analytics } } } className={ classes.advisorViewLink }>
          <div className={ classes.advisorContainer }>
            <div className={ classes.avatarHolder }>
              <AdvisorAvatar { ...advisor } theme={ advisorThemes.staff_pick  } analytics={ analytics } />
            </div>
            <div className={ classes.advisorInfoContainer }>
              {advisorHeader()}
              {staffPickDescription()}
              {footer()}
              <div className={ classes.previewAdvisorCTABottomContainer }>
                {renderStartCallButton(advisor, displayNotifyMe, showGeneralPopup, authPendingAction, displayRegister, location, analytics, handleLinkClick, t)}
              </div>
            </div>
          </div>
        </AppLink>
      </div>
    );
  };

  return renderContent();
}

StaffPickSection.propTypes = {
  ...SectionProps,
  t: PropTypes.func.isRequired,
  displayNotifyMe: PropTypes.func.isRequired,
  showGeneralPopup: PropTypes.func.isRequired,
  authPendingAction: PropTypes.func.isRequired,
  displayRegister: PropTypes.func.isRequired,
  onAnalyticsClick: PropTypes.func,
  sectionSlug: PropTypes.string,
  updateNavigationStack: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

StaffPickSection.defaultProps = {
  ...SectionDefaultProps,
  onAnalyticsClick: null,
  loading: null,
  sectionSlug: null
};

export default StaffPickSection;
