import createReducer from '../config/create_reducer';
import Const, { settingsScreenContent } from '../config/const';

const getDefaultState = () => ({
  loading: false,
  success: false,
  changePasswordInputText: '',
  retypePasswordInputText: '',
  saveButtonActive: false,
  screenContent: settingsScreenContent.settings
});

const settingsReducer = createReducer(getDefaultState(), {
  [Const.settings.contentUpdate]: (state, { screenContent }) => ({
    ...state,
    screenContent
  }),
  [Const.settings.loading]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.settings.success]: (state) => ({
    ...state,
    loading: false,
    success: true
  }),
  [Const.settings.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.settings.saveInputsValue]: (state, {
    changePasswordInputText,
    retypePasswordInputText
  }) => ({
    ...state,
    changePasswordInputText,
    retypePasswordInputText
  }),
  [Const.settings.saveButtonActive]: (state, { saveButtonActive }) => ({
    ...state,
    saveButtonActive
  }),
  [Const.aplicationError.clearError]: () => ({
    ...getDefaultState()
  }),
  [Const.settings.notificationPreferencesLoaded]: (state, { notificationPreferences }) => ({
    ...state,
    screenContent: settingsScreenContent.notification_preferences,
    notificationPreferences,
    loading: false
  })
});

export default settingsReducer;
