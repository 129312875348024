import { connect } from 'react-redux';
import AdvisorsList from './advisors_list';
import withPGTranslation from '../../config/withPGTranslation';

export const advisorsListThemes = {
  searchResult: 'searchResult',
  someOtherAdvisor: 'someOtherAdvisor',
  homePageAllAdvisors: 'homePageAllAdvisors'
};

const mapStateToProps = ({ homePage }) => (
  {
    salesBanner: homePage.salesBanner
  }
);

export default connect(
  mapStateToProps,
  {
  }
)(withPGTranslation(AdvisorsList));
