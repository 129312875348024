import Const from '../config/const';
import { getFeesApi } from '../config/api';
import { calculateDiffHandler, calculateRequiredFunds, getMinPrice } from '../../common/config/utils';
import { purchaseDetailsTypes } from '../../common/config/const';

export const changingPaymentSource = (changing) => (dispatch) => {
  dispatch({ type: Const.purchaseDetails.changingPaymentSource, changing });
};

export const doneChangingPaymentSource = () => (dispatch) => dispatch(changingPaymentSource(false));

export const updatePurchaseDetails = ({
  clickSource, purchaseType, details
}) => (dispatch, getState) => {
  const { user: { clientAvailableCredit } } = getState().user;
  const { price, isPayg } = details;
  const diffPrice = calculateDiffHandler({
    purchaseType, price, clientAvailableCredit
  });
  const isMinPurchase = (diffPrice < getMinPrice() && !isPayg && purchaseType !== purchaseDetailsTypes.AFFILIATE_PROMO_POPUP);
  const requiredCredit = calculateRequiredFunds({ diffPrice, purchaseType });
  getFeesApi({ amountToPay: requiredCredit }).then((fees) => {
    dispatch({
      type: Const.purchaseDetails.updateDetails,
      purchaseType,
      clickSource,
      details: {
        ...details,
        diffPrice,
        isMinPurchase,
        requiredCredit,
        fees
      }
    });
  });
};

export const clearPurchaseDetails = () => ({
  type: Const.purchaseDetails.clearDetails
});
