import React, { useEffect } from 'react';
import { propertiesNames } from '../../../config/const';

const colors = [
  { name: propertiesNames.appColor, value: '#6C84FF' },
  { name: propertiesNames.backgroundSecondary, value: '#F2F5FF' },
  { name: propertiesNames.liveChatChannels, value: '#7BD137' },
  { name: propertiesNames.voiceCallChannels, value: '#6C84FF' },
  { name: propertiesNames.borderPrimary, value: '#DFE2EF' },
  { name: propertiesNames.appOverlayBlackColor, value: '#00000080' },
  { name: propertiesNames.smartBannerBackground, value: 'radial-gradient(circle at 50% 50%, #A8A8FF, #6C84FF 55%, #6C84FF 72%)' },

  { name: '--mbw-general-light', value: '#FFFFFF' },
  { name: '--mbw-general-dark', value: '#000000' },
  { name: '--mbw-background-primary', value: '#FFFFFF' },
  { name: '--mbw-background-secondary', value: '#F2F5FF' },
  { name: '--mbw-background-tertiary', value: '#F7F7F7' },
  { name: '--mbw-background-overlay', value: '#FFFFFF80' },
  { name: '--mbw-text-primary', value: '#121212' },
  { name: '--mbw-text-secondary', value: '#636570' },
  { name: '--mbw-text-tertiary', value: '#FFFFFF' },
  { name: '--mbw-default-border', value: '#E1E1E1' },
  { name: '--mbw-default-shadow-color', value: '#F7F7F7' },
  { name: '--mbw-icon-primary', value: '#121212' },
  { name: '--mbw-icon-secondary', value: '#7C7F89' },
  { name: '--mbw-icon-tertiary', value: '#FFFFFF' },
  { name: '--mbw-button-primary', value: '#6C84FF' },
  { name: '--mbw-button-primary-hover', value: '#8296FF' },
  { name: '--mbw-button-primary-dissabled', value: '#D3D3D3' },
  { name: '--mbw-button-secondary', value: '#121212' },
  { name: '--mbw-button-secondary-hover', value: '#6C84FF' },
  { name: '--mbw-button-secondary-disabled', value: '#636570' },
  { name: '--mbw-chat-color', value: '#7BD137' },
  { name: '--mbw-voice-color', value: '#6C84FF' },
  { name: '--mbw-video-color', value: '#1CB4FF' },
  { name: '--mbw-error-color', value: '#FF5A5A' },
  { name: '--mbw-warning-color', value: '#FF993C' },
  { name: '--mbw-info-color', value: '#6C84FF' },
  { name: '--mbw-success-color', value: '#7BD137' },
  { name: '--mbw-muted-color', value: '#ADB1B6' },
  { name: '--mbw-branding-primary', value: '#6C84FF' },
  { name: '--mbw-branding-secondary', value: '#7BD137' },
  { name: '--mbw-red-accent-color', value: '#FF5A5A' },
  { name: '--mbw-orange-accent-color', value: '#FF8717' },
  { name: '--mbw-yellow-accent-color', value: '#FFB222' },
  { name: '--mbw-green-accent-color', value: '#62DD13' },
  { name: '--mbw-sky-blue-accent-color', value: '#1CB4FF' },
  { name: '--mbw-blue-accent-color', value: '#4F60FF' },
  { name: '--mbw-purple-accent-color', value: '#8755F4' },
  { name: '--mbw-possitive-accent-color', value: '#62DD13' },
  { name: '--mbw-negative-accent-color', value: '#6C84FF' },
  { name: '--wbw-marketing-banner-first-color', value: '#6D84FF' },
  { name: '--mbw-marketing-banner-second-color', value: '#B6D69D' },
  { name: '--mbw-marketing-banner-rotation', value: '93deg' },
  { name: '--mbw-duration-item-selected-fg-color', value: '#6C84FF' },
  { name: '--mbw-duration-item-selected-bg-color', value: '#F2F5FF' },
  { name: '--mbw-select-duration-arrows-color', value: '#121212' },
  { name: '--mbw-duration-item-price-bg-color', value: '#6C84FF' },
  { name: '--mbw-duration-item-price-fg-color', value: '#FFFFFF' },
  { name: '--mbw-duration-item-selected-price-fg-color', value: '#FFFFFF' }
];

const MBWColors = () => {
  useEffect(() => {
    colors.forEach((prop) => document.documentElement.style.setProperty(prop.name, prop.value));
  }, []);
  return (<div />);
};
export default MBWColors;
