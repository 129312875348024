import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import classes from './classes.module.scss';
import { promoCodeTheme } from '../../config/const';
import AppLayout from '../shared/content';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import { trackPageVisit } from '../../actions/analytics';

const image = require('./img/ic_ph_promo.png');

export default class PromoCode extends PureComponent {
  static propTypes = {
    promoCode: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    applyCode: PropTypes.func.isRequired,
    clearModalText: PropTypes.func.isRequired,
    modalText: PropTypes.string,
    t: PropTypes.func.isRequired,
    success: PropTypes.bool.isRequired,
    displayRegister: PropTypes.func.isRequired,
    authPendingAction: PropTypes.func.isRequired,
    theme: PropTypes.string,
    onSussesRead: PropTypes.func
  };

  static defaultProps = {
    promoCode: null,
    modalText: null,
    theme: promoCodeTheme.DEFAULT,
    onSussesRead: null
  };

  constructor(props) {
    super(props);
    this.codeInput = React.createRef();
  }

  componentDidMount() {
    const { displayRegister } = this.props;
    if (!window.currentUserId) {
      displayRegister({ shouldDisplay: true, signUpFlow: 'redeem voucher' });
    } else trackPageVisit('apply promo code');
  }

  onCodeSentClicked = () => {
    const {
      applyCode, authPendingAction, displayRegister, theme
    } = this.props;
    const code = this.codeInput.current.value;
    if (code.length <= 0) {
      return;
    }

    const clickSource = theme === promoCodeTheme.AFFILIATE_PROMO ? 'match up' : 'side menu';

    const codeSent = () => {
      applyCode(code, clickSource);
    };

    const authNeedAction = () => {
      authPendingAction(() => {
        codeSent();
      });
      displayRegister({ shouldDisplay: true, signUpFlow: 'promo code' });
    };

    this.callCallbackOrSetItPending(codeSent, authNeedAction);
  };

  callCallbackOrSetItPending = (authorizedCallback, needToAuthCallback) => {
    if (window.currentUserId) {
      authorizedCallback();
    } else {
      needToAuthCallback();
    }
  };

  clearShowModal = () => {
    const { clearModalText, onSussesRead, success } = this.props;
    clearModalText();
    if (success && onSussesRead) onSussesRead();
  };

  renderPromoText = () => {
    const { t } = this.props;
    return (
      <div className={ classes.text }>
        { t('promoCode.please_enter_your') }
          &nbsp;
        <b>{ t('promoCode.promo_code') }</b>
          &nbsp;
        { t('promoCode.or') }
          &nbsp;
        <br />
        <b>{ t('promoCode.referral_code') }</b>
          &nbsp;
        { t('promoCode.below') }
      </div>
    );
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.target.value.length > 0) {
      this.onCodeSentClicked();
    }
  };

  renderModal() {
    const { modalText, success, t } = this.props;
    if (success) trackPageVisit('promo code success');
    return (
      <AppGeneralPopup visible={ !!modalText } theme={ appGeneralPopupTheme.CENTERED }>
        <div className={ classes.promoCodeModalContainer }>
          <div className={ classes.promoCodeModalHeader }>
            <b>{ t('app_name_title') }</b>
          </div>
          <div className={ classes.promoCodeModalBody }>
            { modalText }
          </div>
          <div className={ classes.modalFooter }>
            <button type="button" value="Promo Code" className={ classes.promoCodeModalOkButton } onClick={ this.clearShowModal }>
              <b>{ t('promoCode.ok') }</b>
            </button>
          </div>
        </div>
      </AppGeneralPopup>
    );
  }

  renderAffiliatePromoCode() {
    const { t, loading, theme } = this.props;
    return (
      <div className = { isMobile ? classes[`mobileContent${ theme }`] : classes[`content${ theme }`] }>
        <div><img src={ image } className = { classes.image } alt="" /></div>
        { this.renderPromoText() }
        <div className={ classes.enterPromoCode }>
          <div>
            <input
              placeholder={ t('promoCode.promo_code_placeholder') }
              ref={ this.codeInput }
              defaultValue={ window.attribution.promo || '' }
              className={ classes.inputContent }
              onKeyDown={ this.handleKeyPress }
            />
          </div>
          <div>
            <button type="button" value="Promo Code" disabled={ loading } onClick={ this.onCodeSentClicked } className={ classes.submitButton }>
              { t('promoCode.submit') }
            </button>
          </div>
        </div>
        { this.renderModal() }
      </div>
    );
  }

  renderPromoCode() {
    const { t, loading, theme } = this.props;
    return (
      <AppLayout fullHeight>
        <div className = { classes.mainContainer }>
          <div className = { isMobile ? classes[`mobileContent${ theme }`] : classes[`content${ theme }`] }>
            <div>
              <img src={ image } className = { classes.image } alt="" />
            </div>
            { this.renderPromoText() }
            <div className = { classes.enterPromoCode }>
              <div>
                <input placeholder={ t('promoCode.promo_code_placeholder') } ref={ this.codeInput } defaultValue={ window.attribution.promo || '' } className={ classes.inputContent } onKeyDown={ this.handleKeyPress } />
              </div>
              <div>
                <button type="button" value="Promo Code" disabled={ loading } onClick={ this.onCodeSentClicked } className={ classes.submitButton }>
                  { t('promoCode.submit') }
                </button>
              </div>
            </div>
            { this.renderModal() }
          </div>
        </div>
      </AppLayout>
    );
  }

  render() {
    const { theme } = this.props;
    if (theme === promoCodeTheme.AFFILIATE_PROMO) {
      return this.renderAffiliatePromoCode();
    }
    return this.renderPromoCode();
  }
}
