import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import SomeOtherAdvisors from '../some_other_advisors';
import { psychicsListTypes } from '../../../config/const';
import { AdvisorDefaultProps } from '../../../reducers/advisor_prop_types';
import AdvisorHeader from '../nested_views/advisor_header';
import { advisorHeaderTheme } from '../../../config/themes';
import AdvisorSpecialities from '../nested_views/advisor_main_content/advisor_specialities';
import classes from './classes.module.scss';
import AppLink from '../../routes/app_link';
import ExpandedText from '../../../../common/helpers/expanded_text';

function TerminatedAdvisor({
  t, clearAplicationError, advisor
}) {
  const {
    specialities, id, aboutMe, loading
  } = advisor;

  const location = useLocation();

  useEffect(() => () => {
    clearAplicationError();
  }, [window.location.pathname]);

  const renderHomeButton = () => (
    <div className={ classes.homeButtonContainer }>
      <AppLink
        to="/"
        className={ classes.homeButton }
        replace
      >
        {  t('terminatedAdvisor.back_to_homepage')}
      </AppLink>
    </div>
  );

  const renderAboutMe = () => (
    <div className={ classes.aboutMeContainer }>
      <div className={ classes.aboutMeTitle }>
        { t('advisorProfile.about_me') }
      </div>
      <ExpandedText
        customClasses={ {
          text: classes.aboutMeText,
          readMoreBtn: classes.aboutMeReadMoreBtn
        } }
        skeleton={ <Skeleton className={ classes.skeleton } /> }
        limit={ 4 }
        loading={ loading }
        readMoreKind="limitTextByRows"
      >
        { aboutMe }
      </ExpandedText>
    </div>
  );

  const renderTerminatedAdvisor = () => (
    <>
      <AdvisorHeader { ...advisor } theme={ advisorHeaderTheme.terminatedAdvisor } />
      <SomeOtherAdvisors
        theme={ psychicsListTypes.TERMINATED }
        title={ t('terminatedAdvisor.this_advisor_is_no_longer_available') }
        subTitle={ t('terminatedAdvisor.here_are_some_other_advisors_who_may_interest_you') }
        location={ location }
      />
      <div className={ classes.specialitiesAndAboutMeContainer }>
        <AdvisorSpecialities
          specialities={ specialities }
          advisorId={ id }
        />
        { renderAboutMe() }
      </div>
      { renderHomeButton() }
    </>
  );

  return renderTerminatedAdvisor();
}

TerminatedAdvisor.propTypes = {
  advisor: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  clearAplicationError: PropTypes.func.isRequired
};

TerminatedAdvisor.defaultProps = {
  ...AdvisorDefaultProps
};

export default TerminatedAdvisor;
