import React from 'react';
import PropTypes from 'prop-types';
import AdvisorsList, { advisorsListThemes } from '../../../advisors_list';
import {
  SectionProps, SectionDefaultProps, advisorsSectionThemes, sectionIds
} from '../../prop_types';
import AdvisorSectionTitle from '../advisors_section/advisor_section_title';
import { isKA } from '../../../../../common/config/utils';

function AllAdvisorsSection({
  title, loading, iconUrl, showMore, slug, content, analytics, params, loadHomePage, allAdvisorsListSection
}) {
  const renderTitle = () => (
    <AdvisorSectionTitle
      title={ title }
      loading={ loading }
      iconUrl={ iconUrl }
      showMore={ showMore }
      slug={ slug }
      theme={ advisorsSectionThemes.all_advisors_section }
      params={ params }
    />
  );

  const loadAdvisorsForHomePageAllAdvisors = (param) => {
    loadHomePage(false, { section_ids: [sectionIds.allAdvisorsList].join(), ...param });
  };

  const renderAdvisorsList = () => {
    if (isKA()) {
      return (
        <AdvisorsList
          advisors={ content.advisors }
          theme={ advisorsListThemes.searchResult }
          loading={ loading }
          analytics={ analytics }
          slug={ slug }
          params={ params }
          loadAdvisors={ loadAdvisorsForHomePageAllAdvisors }
          page={ allAdvisorsListSection.page }
          lastPageNum={ allAdvisorsListSection.lastPageNum || 2 }
        />
      );
    }
    return (
      <AdvisorsList
        advisors={ content.advisors }
        theme={ advisorsListThemes.homePageAllAdvisors }
        loading={ loading }
        analytics={ analytics }
        slug={ slug }
        params={ params }
      />
    );
  };

  const renderContent = () => {
    if (!(content || {}).advisors) return null;
    return (
      <div style={ { width: '100%' } } suppressHydrationWarning>
        { renderTitle() }
        {renderAdvisorsList() }
      </div>
    );
  };

  return renderContent();
}

AllAdvisorsSection.propTypes = {
  ...SectionProps,
  loadHomePage: PropTypes.func.isRequired,
  allAdvisorsListSection: PropTypes.object
};

AllAdvisorsSection.defaultProps = {
  SectionDefaultProps,
  allAdvisorsListSection: {}
};

export default AllAdvisorsSection;
