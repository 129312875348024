import { matchPaths, addTrailingSlashToPathIfNeed } from '../../common/config/utils';
import { appSpecificRoute } from '../project_items/helper_functions';
import i18n, { getCurrentLocalization, LANGUAGE } from './i18n';

export const appRoutesObject = {
  home: '/',
  esHome: '/es',
  psychics: appSpecificRoute.psychicList,
  advisor: appSpecificRoute.psychic,
  advisorES: `es${ appSpecificRoute.psychic }`,
  myOrders: appSpecificRoute.orderList,
  advisorOrders: appSpecificRoute.advisorList,
  orderDetails: appSpecificRoute.orderDetails,
  categories: '/categories',
  favoriteAdvisors: '/favorite-advisors',
  inviteFriends: '/free_readings',
  addFunds: '/add_funds',
  promoCode: '/promo',
  notifyMe: '/notify_me/:advisorId',
  transcript: '/transcript/:orderId',
  settings: '/settings',
  cashback : '/cashback',
  paymentSources: '/my/payment_sources',
  emptyPreload: '/empty',
  spendLimits: '/spend_limits',
  register: '/register',
  signin: '/signin',
  purchaseDetails: '/purchase_details',
  trendingPsychics: '/trending-psychics',
  highestRatedPsychics: '/highest-rated-psychics',
  recommendedPsychics: '/recommended-psychics',
  mostAccuratePsychics: '/most-accurate-psychics'
};

const addTrailingSlashToAppRoutesIfNeed = () => {
  Object.keys(appRoutesObject).forEach((r) => {
    appRoutesObject[r] = addTrailingSlashToPathIfNeed({ path: appRoutesObject[r] });
  });
  return appRoutesObject;
};

export const appRoutes = { ...addTrailingSlashToAppRoutesIfNeed() };
// to be removed when app versions will be updated
// export const temporaryRoutes = {
//   temporaryPaymentSources: '/en/my/payment_sources',
//   temporaryPaymentMethods: '/en/payment_methods'
// };

export const updatedRoutesWithTrailingSlashIfNeeded = ({ routes }) => {
  let updatedRoutesWithTrailingSlash = {};
  Object.keys(routes).forEach((r) => {
    const updatedRoute = addTrailingSlashToPathIfNeed({ path: r });
    updatedRoutesWithTrailingSlash = { ...updatedRoutesWithTrailingSlash, [updatedRoute]: routes[r] };
  });
  return updatedRoutesWithTrailingSlash;
};

const getWindowRoutes = () => {
  const uiRoutes = window.config.uiSettings.routes;
  let windowRoutes = {};
  Object.keys(uiRoutes).forEach((url) => {
    let updatedWindowRouteData;

    Object.keys(appRoutes).find((ar) => {
      const appRoute = appRoutes[ar];
      if (appRoute === url.replace('_', '-')) {
        updatedWindowRouteData = { [appRoute]: uiRoutes[url] };
        return true;
      }
      if (`/${ LANGUAGE.es }${ appRoute }` === url.replace('_', '-')) {
        updatedWindowRouteData = { [`/${ LANGUAGE.es }${ appRoute }`]: uiRoutes[url] };
        return true;
      }
      return false;
    });

    if (updatedWindowRouteData) {
      windowRoutes = { ...windowRoutes, ...updatedWindowRouteData };
    } else {
      windowRoutes = { ...windowRoutes, [url]: uiRoutes[url] };
    }
  });

  return updatedRoutesWithTrailingSlashIfNeeded({ routes: windowRoutes });
};

export const windowRoutes = {
  routes: getWindowRoutes()
};

export const localizedCategories = Object.keys(windowRoutes.routes);

export const seoDataCategoriesRoutes = () => {
  const route = localizedCategories.some(
    pathname => {
      const appRoutesArray = Object.keys(appRoutes).map((key) => appRoutes[key]);
      const match = matchPaths({
        pathname: [...appRoutesArray, pathname],
        path: window.location.pathname
      });
      return  window.location.pathname.includes(pathname) && pathname.length > window.location.pathname.length && window.location.pathname !== pathname && !match;
    }
  ) ? window.location.pathname : null;
  return route;
};

export const needRegisterRoutes = {
  myOrders: appRoutes.myOrders,
  advisorOrders: appRoutes.advisorOrders,
  orderDetails: appRoutes.orderDetails,
  favoriteAdvisors: '/favorite_advisors',
  inviteFriends: '/free_readings',
  addFunds: '/add_funds',
  notifyMe: '/notify_me/:advisorId',
  transcript: '/transcript/:orderId',
  settings: '/settings',
  cashback : '/cashback',
  paymentSources: '/my/payment_sources',
  promoCode: '/promo',
  spendLimits: '/spend_limits'
};

export const needReplaceHeaderSearchRoutes = {
  ...Object.keys(windowRoutes.routes),
  psychics: appRoutes.psychics
};

export const shouldShowNavigationStackBarRoutes = {
  ...Object.keys(windowRoutes.routes),
  advisorEN: `${ appRoutes.advisor }`,
  advisorES: `/es${ appRoutes.advisor }`,
  myOrdersEN: `${ appRoutes.myOrders }`,
  myOrdersES: `/es${ appRoutes.myOrders }`,
  advisorOrdersEN: `${ appRoutes.advisorOrders }`,
  advisorOrdersES: `/es${ appRoutes.advisorOrders }`
};

export const shouldSetNavigationStackBarRoutes = {
  ...Object.keys(windowRoutes.routes),
  advisorEN: `${ appRoutes.advisor }`,
  advisorES: `/es${ appRoutes.advisor }`,
  homeEN: appRoutes.home,
  homeES: appRoutes.esHome,
  myOrdersEN: `${ appRoutes.myOrders }`,
  myOrdersES: `/es${ appRoutes.myOrders }`,
  advisorOrdersEN: `${ appRoutes.advisorOrders }`,
  advisorOrdersES: `/es${ appRoutes.advisorOrders }`
};

export const shouldClearNavigationStackBarRoutes = {
  myOrdersEN: `${ appRoutes.myOrders }`,
  myOrdersES: `/es${ appRoutes.myOrders }`
};

export const shouldGetSEODataRoutes = {
  ...Object.keys(windowRoutes.routes),
  homeEN: appRoutes.home,
  homeES: appRoutes.esHome,
  categoriesSeoDataRoute: seoDataCategoriesRoutes() || ''
};

const createCategoryData = ({ categotyRoute, screenStack, key }) => {
  let dataKey = key === 'categoryId' ?  windowRoutes.routes[categotyRoute].id : categotyRoute;
  dataKey = addTrailingSlashToPathIfNeed({ path: dataKey });
  const routeData = {
    [dataKey]: {
      name: windowRoutes.routes[categotyRoute]?.name,
      slug: categotyRoute,
      params: {
        params:{ ...windowRoutes.routes[categotyRoute]?.params },
        needRefresh: true,
        trackCategoryEvent: true
      },
      directScreenStack: { ...screenStack },
      type: 'specialities'
    }
  };
  return routeData;
};

const directScreenStackDefaultCategory = () => {
  if (process.env.APP_CONTEXTS === 'ka') return '/psychic-reading/';
  if (getCurrentLocalization() === LANGUAGE.es) return  '/es/lecturas-psiquicas';
  return '/psychic-readings';
};

const directScreenStack = {
  advisor: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home },
    ...createCategoryData({ categotyRoute: directScreenStackDefaultCategory() })
  },
  categoty: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home }
  },
  myOrders: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home }
  },
  advisorOrders: {
    [appRoutes.home]: { name: i18n.t('sideMenu.home'), slug: appRoutes.home },
    [appRoutes.myOrders]: { name: i18n.t('orders.title'), slug: appRoutes.myOrders }
  }

};

const createCategoryNavigationStackBarRoutesData = ({ screenStack, key }) => {
  let data = {};
  const routes = Object.keys(windowRoutes.routes);
  routes.forEach((r) => {
    const routeData = createCategoryData({ categotyRoute: r, screenStack, key });
    data = { ...data, ...routeData };
  });
  return data;
};

export const navigationStackBarRoutesData = {
  [`${ appRoutes.advisor }`]: { directScreenStack: { ...directScreenStack.advisor  }, type: 'advisor' },
  [`/es${ appRoutes.advisor }`]: { directScreenStack: { ...directScreenStack.advisor  }, type: 'advisor' },
  [appRoutes.home]: { name: 'Home', slug: appRoutes.home },
  [appRoutes.esHome]: { name: 'Inicio', slug: appRoutes.esHome },
  ...createCategoryNavigationStackBarRoutesData({ screenStack: directScreenStack.categoty }),
  [appRoutes.myOrders]: {
    directScreenStack: { ...directScreenStack.myOrders  }, name: i18n.t('orders.title'), slug: appRoutes.myOrders, type: 'myOrders'
  },
  [`/es${ appRoutes.myOrders }`]: {
    directScreenStack: { ...directScreenStack.myOrders  },  name: i18n.t('orders.title'), slug: appRoutes.myOrders, type: 'myOrders'
  },
  [appRoutes.advisorOrders]: {
    directScreenStack: { ...directScreenStack.advisorOrders  }, type: 'advisor'
  },
  [`/es${ appRoutes.advisorOrders }`]: {
    directScreenStack: { ...directScreenStack.advisorOrders  }, type: 'advisor'
  }
};

export const notShowHeaderSearchRoutes = {
  home: appRoutes.home
};

export const showSmartBannerRoutes = {
  ...Object.keys(windowRoutes.routes),
  homeEN: appRoutes.home,
  homeES: appRoutes.esHome
};

export const shouldShowNewToPGRoutes = {
  psychics: appRoutes.psychics
};

export const categoriesRoutes = [
  appRoutes.highestRatedPsychics, `/es${ appRoutes.highestRatedPsychics }`,
  appRoutes.mostAccuratePsychics, `/es${ appRoutes.mostAccuratePsychics }`,
  appRoutes.recommendedPsychics, `/es${ appRoutes.recommendedPsychics }`,
  appRoutes.trendingPsychics, `/es${ appRoutes.trendingPsychics }`
];

export const allAdvisors = [appRoutes.psychics, `/es${ appRoutes.psychics }`];

export const showBannerRoutes = {
  ...Object.keys(windowRoutes.routes),
  homeEN: appRoutes.home,
  homeES: appRoutes.esHome,
  advisorEN: `${ appRoutes.advisor }`,
  advisorES: `/es${ appRoutes.advisor }`,
  purchaseDetails: appRoutes.purchaseDetails
};
