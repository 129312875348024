import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { getPosition } from 'react-responsive-carousel/lib/js/components/Carousel/utils';
import classes from './classes.module.scss';
import DurationItem from './duration_item';
import withPGTranslation from '../../../../config/withPGTranslation';
import {
  carouselHandleKeyPress, carouselSwipeMoveAction, getDurationOptionsClass, onCarouselSetSelected,
  carouselArrowPrevHandler, carouselArrowNextHandler, renderNewUserPaygPrice, renderNewUserPaygLabel
} from './duration_selector_utils';
import { trackPageVisit } from '../../../../actions/analytics';
import Const from '../../../../config/const';

const newUserPaygIcon = require('./img/new_user_payg_icon.svg');

function DurationSelector({
  selected, isContinue, pricingOptions, onDurationClick,
  selectedCarouselDefaultItem, disabledStartChatButton, carouselRef,
  isNewUser, setDefaultTransform, onKeyDown, loading, onSelect, selectedCarouselIndex, advisorXfmProgram, userXfmProgram, selectDurationKind
}) {
  const [carouselSwipeCurrentPosition, setCarouselSwipeCurrentPosition] = useState(0);
  const carouselArrowPrevRef = useRef(null);
  const carouselArrowNextRef = useRef(null);

  const carouselSwipeStartAction = (e) => {
    setCarouselSwipeCurrentPosition(e.changedTouches[0].pageX);
  };

  const carouselSetSelected = () => {
    setTimeout(() => {
      onCarouselSetSelected(carouselRef, pricingOptions, onSelect);
      return () => {};
    }, 100);
  };

  const carouselOnclickArrowNext = () => {
    carouselRef.current.onClickNext();
    carouselSetSelected();
  };

  const carouselOnclickArrowPrev = () => {
    carouselRef.current.onClickPrev();
    carouselSetSelected();
  };

  const carouselSwipeEndAction = () => {
    const currentPosition = getPosition(carouselRef.current.state.selectedItem, carouselRef.current.props);
    const transform = `translate3d(${ currentPosition }%, 0px, 0px);`;
    setDefaultTransform(
      carouselRef.current.state.selectedItem,
      carouselRef,
      pricingOptions,
      transform
    );
  };

  const onCarouselKeyPress = (e) => {
    carouselHandleKeyPress(
      e,
      carouselOnclickArrowNext,
      carouselOnclickArrowPrev,
      carouselRef,
      pricingOptions
    );
  };

  useEffect(() => {
    if (isContinue) {
      trackPageVisit('add time popup');
    } else trackPageVisit('duration picker popup');
    document.activeElement.blur();
  }, []);

  const onCarouselSwipe = (e) => {
    carouselSwipeMoveAction({
      e,
      carouselRef,
      carouselSwipeCurrentPosition,
      setCarouselSwipeCurrentPosition,
      carouselSetSelected,
      pricingOptions,
      getCarouselPosition: getPosition
    });
  };

  useEffect(() => {
    if (!carouselRef.current) return () => {};
    const list = carouselRef.current.listRef || null;
    if (list) {
      list.addEventListener('touchmove', onCarouselSwipe, { passive: true });
      return () => list.removeEventListener('touchmove', onCarouselSwipe, { passive: true });
    }
    return () => {};
  }, [carouselSwipeCurrentPosition]);

  useEffect(() => {
    const list = carouselRef.current && carouselRef.current.listRef;
    const carouselWrapper = carouselRef.current && carouselRef.current.carouselWrapperRef;
    if (!loading && list && carouselWrapper) {
      list.addEventListener('touchstart', carouselSwipeStartAction, { passive: true });
      list.addEventListener('touchend', carouselSwipeEndAction);
      carouselWrapper.addEventListener('keydown', onCarouselKeyPress);
      carouselWrapper.focus();
      return () => {
        list.removeEventListener('touchstart', carouselSwipeStartAction, { passive: true });
        list.removeEventListener('touchend', carouselSwipeEndAction);
        carouselWrapper.removeEventListener('keydown', onCarouselKeyPress);
      };
    }
    return () => {};
  }, [loading, carouselRef.current]);

  const carouselArrowPrev = () => (
    carouselArrowPrevHandler({
      carouselRef, carouselOnclickArrowPrev, onKeyDown, carouselArrowPrevRef
    })
  );

  const carouselArrowNext = () => (
    carouselArrowNextHandler({
      carouselRef,
      pricingOptions,
      carouselOnclickArrowNext,
      onKeyDown,
      carouselArrowNextRef
    })
  );

  const renderDurationOptions = (options) => (
    options.map((option, index) => (
      <div
        key={ option.duration }
        className={
          getDurationOptionsClass(option, selected, index, selectedCarouselIndex, isNewUser)
        }
      >
        <DurationItem
          { ...option }
          onClickAction={ () => onDurationClick(option, index) }
          isContinue={ isContinue }
          disabledStartChatButton={ disabledStartChatButton }
          isNewUser={ isNewUser }
          advisorXfmProgram={ advisorXfmProgram }
          userXfmProgram={ userXfmProgram }
          selected={ selected }
        />
      </div>
    ))
  );

  const renderNewUserDurationOptions = () => (
    <div className={ classes.newUserDurationOptions }>{renderDurationOptions(pricingOptions.filter(({ additional }) => !additional))}</div>
  );

  const renderNewUserPayg = () => {
    const { price, salesPrice, bonusItems } = selected;
    return (
      <div className={ classes.newUserPaygContainer }>
        <div className={ classes.newUserPaygImgContainer }>
          <img src={ newUserPaygIcon } alt="" className={ classes.newUserPaygImg } />
        </div>
        <div className={ classes.newUserPaygTitle }>
          Zen mode
        </div>
        <div className={ classes.newUserPaygPayAsYouGoText }>
          Pay as you go
        </div>
        { renderNewUserPaygPrice({ price, salesPrice }) }
        <div className={ classes.newUserPaygBonusLabelsHolder }>
          {
            bonusItems?.map(bonusItem => (
              renderNewUserPaygLabel({ bonusItem })
            ))
          }
        </div>

      </div>
    );
  };

  const renderCarousel = () => (
    <Carousel
      showIndicators={ false }
      showThumbs={ false }
      showStatus={ false }
      className = "select-chat-credit-carousel"
      selectedItem={ selectedCarouselDefaultItem }
      ref={ carouselRef }
      renderArrowPrev={ carouselArrowPrev }
      renderArrowNext={ carouselArrowNext }
      swipeable={ false }
      centerSlidePercentage= { 66 }
      centerMode
      showArrows={ false }
      onClickItem={ carouselSetSelected }
    >
      {renderDurationOptions(pricingOptions)}
    </Carousel>
  );

  const renderDurationSelector = () => {
    let content;
    switch (selectDurationKind) {
      case Const.selectDurationKind.newUser:
        content = renderNewUserDurationOptions();
        break;
      case Const.selectDurationKind.newUserPayg:
        content = renderNewUserPayg();
        break;
      default:
        content = renderCarousel();
        break;
    }
    return content;
  };

  return (
    <div className={ classes.durationOptinsContainer } style={ isNewUser ? { overflow: 'unset' } : {} }>
      <div onKeyDown={ onKeyDown }>
        { renderDurationSelector() }
      </div>
    </div>
  );
}

DurationSelector.propTypes = {
  selected: PropTypes.object,
  isContinue: PropTypes.bool,
  pricingOptions: PropTypes.array,
  selectedCarouselDefaultItem: PropTypes.number,
  disabledStartChatButton: PropTypes.bool.isRequired,
  carouselRef: PropTypes.object,
  onDurationClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isNewUser: PropTypes.bool,
  setDefaultTransform: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedCarouselIndex: PropTypes.number,
  advisorXfmProgram: PropTypes.object,
  userXfmProgram: PropTypes.object,
  selectDurationKind: PropTypes.string
};

DurationSelector.defaultProps = {
  selected: null,
  isContinue: false,
  pricingOptions: [],
  selectedCarouselDefaultItem: null,
  carouselRef: null,
  loading: false,
  isNewUser: false,
  selectedCarouselIndex: null,
  advisorXfmProgram: {},
  userXfmProgram: {},
  selectDurationKind: null
};

export default withPGTranslation(DurationSelector);
