import mixpanel from 'mixpanel-browser';
import { getUA } from 'react-device-detect';
import env from '../config/variables';
import { log } from '../config/app_logger';
import { updateUser, userMPIdentify } from './user';
import { saveDataToStorage, getDataFromStorage, isPrerenderMode } from '../config/util';
import { fromMobileApp } from '../../common/config/utils';
// eslint-disable-next-line import/no-cycle
import { trackGTMEvent } from './google_tag_mahager_events';
import { getBaseSuperProperties, getBaseProfileProperties } from './mixpanel_properties';

const {
  MP_TOKEN, REPORT_SESSION_BEGAN, SESSION_BEGAN_TIMER
} = env;

let mpConfigured = false;
let sessionBeganTracked = false;
let identifyId = 0;

const trackGTMWithSuperProperties = (getState) => {
  const { user: { user } } = getState();
  const baseSuperProperties = getBaseSuperProperties({ user });
  return baseSuperProperties;
};

const trackPeopleProperties = (getState) => {
  if (!mpConfigured || !window.currentUserId) return;
  // This is not needed because according to MP documentation
  // MP doesn't creat profile on MP if identify was not called,
  // only stores the profile locally
  // if (identify === 0) return;
  const { user } = getState().user || {};
  if (user && user.id) {
    const baseProfileProperties = getBaseProfileProperties();
    mixpanel.people.set(baseProfileProperties);
  }
};

export const trackPeoplePropertiesAction = () => (dispatch, getState) => {
  trackPeopleProperties(getState);
};

const trackSuperProperties = (getState) => {
  const { user: { user } } = getState();
  log('Super properties are being tracked');
  const baseSuperProperties = getBaseSuperProperties({ user });
  log('baseSuperProperties!!', baseSuperProperties);
  mixpanel.register(baseSuperProperties);
};

export const trackEvent = (eventName, eventProperties) => (dispatch, getState) => {
  if (isPrerenderMode()) return;
  if (!mpConfigured) return;
  if (isPrerenderMode()) return;
  const { user } = window.store.getState();
  if (user && user.impersonated) return;
  const logEventProperties = { ...eventProperties };
  log('Mixpanel', `track event: ${ eventName }`);
  log('eventProperties', logEventProperties);
  trackPeopleProperties(getState);
  trackSuperProperties(getState);
  mixpanel.track(eventName, eventProperties);
  trackGTMEvent(eventName, { ...eventProperties, ...trackGTMWithSuperProperties(getState) });
};

export const resetMP = () => {
  identifyId = 0;
  mixpanel.reset();
};

export const updateLastHit = () => {
  if  (!mpConfigured || !sessionBeganTracked) return;
  saveDataToStorage('LAST_HIT', new Date());
};

const ignoreBySessionParamLogic = () => {
  if (REPORT_SESSION_BEGAN === 'none') return true;
  if (REPORT_SESSION_BEGAN === 'user' && !window.currentUserId) return true;
  if (REPORT_SESSION_BEGAN === 'attributed'
  && (window.attribution.mixpanel.media_status === 'Organic'
  || window.currentUserId === undefined)) return true;
  return false;
};

const ignoreTrackSessionBeganEvent = (lastHit, lastHitSec) => {
  if (getUA.includes('HeadlessChrome') || fromMobileApp) return true;
  if (lastHit && lastHitSec / 60 < parseInt(SESSION_BEGAN_TIMER, 10)) return true;
  return ignoreBySessionParamLogic();
};

const trackSessionBeganEvent = () => (dispatch) => {
  sessionBeganTracked = true;
  const lastHit = getDataFromStorage('LAST_HIT');
  const lastHitSec = (new Date() - new Date(lastHit)) / 1000;
  if (ignoreTrackSessionBeganEvent(lastHit, lastHitSec)) return;
  const sessionCount = parseInt(getDataFromStorage('BROWSER_SESSIONS'), 10) || 0;
  dispatch(trackEvent('session began', {
    'sessions count': sessionCount,
    'time from last session': parseInt(lastHitSec, 10)
  }));
  saveDataToStorage('BROWSER_SESSIONS', sessionCount + 1);
  updateLastHit();
};

const addMPAttributions = () => {
  if (window.attribution && window.attribution.mixpanel) {
    mixpanel.register(window.attribution.mixpanel);
  }
};

const resetMPIfNeed = () => {
  if (!window.currentUserId && mixpanel.get_property('user id')) resetMP();
};

export const configureMP = () => (dispatch) => {
  if (isPrerenderMode()) return;
  if (mpConfigured) return;
  mixpanel.init(MP_TOKEN, { loaded: resetMPIfNeed });
  addMPAttributions();
  mpConfigured = true;
  if (!window.currentUserId) {
    dispatch(trackSessionBeganEvent());
  }
};

const identify = (id) => {
  identifyId = id;
  mixpanel.identify(id);
};

const registerUser = (id) => {
  if (!mpConfigured) return;
  identifyId = id;
  mixpanel.alias(id);
};

export const identifyOrAliasUserOnMP = (user) => (dispatch, getState) => {
  const mpUser = user || getState().user;
  const { user: { id, mixpanelAliasCreated } } = mpUser;
  if (!mpConfigured || id === identifyId) return;
  if (mixpanelAliasCreated) {
    log('Mixpanel', 'identify');
    identify(id);
    dispatch(userMPIdentify());
    dispatch(trackSessionBeganEvent());
    return;
  }
  log('Mixpanel', 'registerUser');
  registerUser(id);
  dispatch(updateUser({ mixpanelAliasCreated: true }));
  dispatch(userMPIdentify());
};
