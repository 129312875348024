import React from 'react';
import PropTypes from 'prop-types';
import withPGTranslation from '../../../config/withPGTranslation';
import { purchaseDetailsTypes, paymentSourcesTheme } from '../../../../common/config/const';
import PurchaseDetailsCommon from '../../../../common/purchase_details_common/purchase_details_common';
import { PaymentSourcesListType } from '../../../../common/payment_methods_list/payment_sources_prop_types';
import { log } from '../../../config/app_logger';
import PayPalProcessing from '../../purchases/pay_pal_processing';
import CreditCardProcessing from '../../purchases/credit_card_processing';
import { getCurrentLocalization } from '../../../config/i18n';
import { getAddFundsInitialChargedAndInitialDurationPropsNames } from '../../../config/util';
import { trackPageVisit } from '../../../actions/analytics';
import CreditCardValidateProcessing from '../../purchases/credit_card_validate_processing';

function PurchaseDetails({
  purchaseDetails, clientAvailableCredit, hidePaymentDetails, loadAvailable,
  purchaseSuccess, options, loadingOptions, trackPaymentMethodChanged,
  sideMenuPurcahseSuccess, isContinue, chatType, affiliatePromoPurcahseSuccess,
  doneChangingPaymentSource, doneAddCard, paymentSources,
  addSource, startEditingPaymentSource, trackPaymentSourcesEvent,
  managePaymentSource, onMakeDefault, loadingSources, doneManagingPaymentSource,
  editPaymentSource, updatePaymentSource, trackStartAddingCreditCard,
  trackCreditCardAdded, trackAddCreditCardFailed, clearAddingCardError,
  loadPaymentOptions, changingPaymentSource, trackMPEventAdditionalFundsRequired,
  reset, hideAffiliatePromoPopup, noThanksLinkClickedAction, backArrowAction, userMPIdentify,
  editCardIfSecurityReasons, isPayg, creditCardValidateError, loadPaymentOptionsOnClose, affiliatePromoPopupOnCloseIconClick
}) {
  const {
    bonus, clickSource, purchaseType, flow
  } = purchaseDetails;

  const trackAddFundsScreenEvent = (funds) => {
    const {
      initialChargedPropName, initialDurationPropName
    } = getAddFundsInitialChargedAndInitialDurationPropsNames({ chatType });
    const additionalFundsParams = {
      options,
      clickSource,
      credit: funds,
      bonus: bonus || 0,
      initialChargedPropName,
      initialDurationPropName,
      payAsYouGo: isPayg,
      flow
    };
    trackMPEventAdditionalFundsRequired(additionalFundsParams);
  };

  const onPurchaseDetailsScreenOpened = () => {
    loadPaymentOptions();
    loadAvailable(isPayg);
    trackPageVisit('purchase credit? draw');
    return () => {
      reset();
    };
  };

  const  onPurchaseComplete = () => {
    if (purchaseType === purchaseDetailsTypes.SIDE_MENU) {
      sideMenuPurcahseSuccess();
      return;
    }
    if (purchaseType === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) {
      affiliatePromoPurcahseSuccess();
      return;
    }
    purchaseSuccess();
  };

  const paymentProcessing = (currentPaymentOption, purchaseURL, purchaseAmount, callback) => (
    <PayPalProcessing
      purchaseURL={ purchaseURL }
      onPurchaseComplete={ onPurchaseComplete }
      type={ purchaseType }
      purchaseAmount={ purchaseAmount }
      currentPaymentOption={ currentPaymentOption }
      hasPaypalBillingAgreement={ (currentPaymentOption.context || {}).hasPaypalBillingAgreement  }
      bonus={ bonus }
      isContinue={ isContinue }
      chatType={ chatType }
    >
      { callback() }
    </PayPalProcessing>
  );

  const renderCreditCardProcessing = (disableBuyButton, isMinPurchase, currentPaymentOption, purchaseURL, purchaseAmount, callback) => (
    <CreditCardProcessing
      purchaseURL={ purchaseURL }
      onPurchaseComplete={ onPurchaseComplete }
      type={ purchaseType }
      purchaseAmount={ purchaseAmount }
      isMinPurchase= { isMinPurchase }
      disabled={ !!disableBuyButton }
      currentPaymentOption={ currentPaymentOption }
      bonus={ bonus }
      isContinue={ isContinue }
      chatType={ chatType }
    >
      { callback() }
    </CreditCardProcessing>
  );

  const renderPayPalProcessing = (disableBuyButton, isMinPurchase, currentPaymentOption, purchaseURL, purchaseAmount, callback) => (
    <PayPalProcessing
      purchaseURL={ purchaseURL }
      onPurchaseComplete={ onPurchaseComplete }
      type={ purchaseType }
      purchaseAmount={ purchaseAmount }
      isMinPurchase= { isMinPurchase }
      disabled={ !!disableBuyButton }
      currentPaymentOption={ currentPaymentOption }
      bonus={ bonus }
      isContinue={ isContinue }
      chatType={ chatType }
    >
      { callback() }
    </PayPalProcessing>
  );

  const renderCreditCardValidateProcessing = ({ disableBuyButton, requiresPayAsYouGoValidation }) => (
    <CreditCardValidateProcessing
      onCreditCardValidated={ onPurchaseComplete }
      disableBuyButton={ disableBuyButton }
      loading={ loadingSources }
      requiresPayAsYouGoValidation={ requiresPayAsYouGoValidation }
    />
  );

  const hideAffiliatePromoPopupAction = () => {
    hideAffiliatePromoPopup();
    noThanksLinkClickedAction();
  };

  const closeAffiliatePromoPopupAction = () => {
    if (affiliatePromoPopupOnCloseIconClick) {
      affiliatePromoPopupOnCloseIconClick();
    }
  };

  const showPaymentSources = (kind) => {
    log('CC', '<Option/> changing PaymentSource');
    changingPaymentSource(kind || true);
  };

  const addCreditCard = () => {
    log('CC', ' <Option/> on add Credit card');
    addSource('cc');
    showPaymentSources('cc');
  };

  const paymentMethodListActions = {
    loadAvailable,
    addSource,
    startEditingPaymentSource,
    managePaymentSource,
    doneManagingPaymentSource,
    getAddCardCinfig: editPaymentSource,
    updatePaymentSource,
    doneAddCard,
    clearAddingCardError,
    doneChangingPaymentSource,
    showPaymentSources,
    loadPaymentOptions,
    editCardIfSecurityReasons
  };

  const paymentMethodListAnalyticsEvents = {
    trackPaymentSourcesEvent,
    trackPaymentMethodChanged,
    trackStartAddingCreditCard,
    trackCreditCardAdded,
    trackAddCreditCardFailed
  };

  const purchaseDetailsActions = {
    hidePaymentDetails : hidePaymentDetails ?? closeAffiliatePromoPopupAction,
    backArrowAction,
    onPurchaseDetailsScreenOpened,
    renderCreditCardProcessing,
    renderPayPalProcessing,
    paymentProcessing,
    getCurrentLocalization,
    addCreditCard,
    renderCreditCardValidateProcessing,
    hideAffiliatePromoPopupAction
  };

  const renderPurchaseDetailsCommon = () => {
    const purchaseDetailsCommonProps = {
      ...purchaseDetailsActions,
      paymentMethodListActions,
      paymentMethodListAnalyticsEvents,
      trackAddFundsScreenEvent,
      showPaymentSources,
      onMakeDefault,
      log,
      clientAvailableCredit,
      options,
      loadingOptions,
      loadingSources,
      paymentSources,
      theme: paymentSourcesTheme.purchase,
      userMPIdentify,
      purchaseDetails,
      trackPageVisit,
      isPayg,
      creditCardValidateError,
      loadPaymentOptionsOnClose
    };
    return <PurchaseDetailsCommon { ...purchaseDetailsCommonProps } />;
  };

  return renderPurchaseDetailsCommon();
}

PurchaseDetails.propTypes = {
  purchaseDetails: PropTypes.object,
  clientAvailableCredit: PropTypes.string,
  purchaseSuccess: PropTypes.func.isRequired,
  hasPaypalBillingAgreement: PropTypes.bool,
  isContinue: PropTypes.bool,
  sideMenuPurcahseSuccess: PropTypes.func.isRequired,
  chatType: PropTypes.string.isRequired,
  affiliatePromoPurcahseSuccess: PropTypes.func.isRequired,
  doneChangingPaymentSource: PropTypes.func.isRequired,
  paymentSources: PropTypes.shape(PaymentSourcesListType),
  loadAvailable: PropTypes.func.isRequired,
  addSource: PropTypes.func.isRequired,
  startEditingPaymentSource: PropTypes.func.isRequired,
  managePaymentSource: PropTypes.func.isRequired,
  trackPaymentSourcesEvent: PropTypes.func.isRequired,
  onMakeDefault: PropTypes.func.isRequired,
  loadingSources: PropTypes.bool.isRequired,
  doneManagingPaymentSource: PropTypes.func.isRequired,
  trackPaymentMethodChanged: PropTypes.func.isRequired,
  hidePaymentDetails: PropTypes.func,
  options: PropTypes.array,
  loadPaymentOptions: PropTypes.func.isRequired,
  changingPaymentSource: PropTypes.func.isRequired,
  trackMPEventAdditionalFundsRequired: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  hideAffiliatePromoPopup: PropTypes.func.isRequired,
  noThanksLinkClickedAction: PropTypes.func.isRequired,
  loadingOptions: PropTypes.bool.isRequired,
  backArrowAction: PropTypes.func,
  editPaymentSource: PropTypes.func.isRequired,
  updatePaymentSource: PropTypes.func.isRequired,
  trackStartAddingCreditCard: PropTypes.func.isRequired,
  doneAddCard: PropTypes.func.isRequired,
  trackCreditCardAdded: PropTypes.func.isRequired,
  trackAddCreditCardFailed: PropTypes.func.isRequired,
  clearAddingCardError: PropTypes.func.isRequired,
  userMPIdentify: PropTypes.bool.isRequired,
  editCardIfSecurityReasons: PropTypes.func.isRequired,
  creditCardValidateError: PropTypes.object,
  loadPaymentOptionsOnClose: PropTypes.func.isRequired,
  affiliatePromoPopupOnCloseIconClick: PropTypes.func.isRequired
};

PurchaseDetails.defaultProps = {
  purchaseDetails: {},
  hasPaypalBillingAgreement: false,
  clientAvailableCredit: '0',
  isContinue: false,
  paymentSources: {},
  options: [],
  backArrowAction: null,
  hidePaymentDetails: null,
  creditCardValidateError: null
};
export default withPGTranslation(PurchaseDetails);
