import { connect } from 'react-redux';
import AnalyticsAndCommonLogicComponent from './common_logic_component';
import { parseAndUploadLogs } from '../../actions/aws';

import { configureMP, trackPeoplePropertiesAction, identifyOrAliasUserOnMP } from '../../actions/mixpanel';
import { getMyAccountCredits, getUser } from '../../actions/user';
import { initFirebase, requestPermission } from '../../actions/firebase_messaging';
import { loadHomePage } from '../../actions/home_page';
import { appLoading } from '../../actions/application';

const mapStateToProps = ({ user }) => ({
  userId: user && user.user && user.user.id,
  userEmail: user && user.user && user.user.email,
  user: user && user.user,
  uploadLogs: ((user || {}).user || {}).uploadLogs,
  logUrl: ((user || {}).user || {}).logUrl,
  logKey: ((user || {}).user || {}).logKey,
  userPaymentProcessing: ((user || {}).user || {}).paymentProcessing
});

export default connect(
  mapStateToProps,
  {
    configureMP,
    trackPeoplePropertiesAction,
    getUser,
    initFirebase,
    requestPermission,
    parseAndUploadLogs,
    identifyOrAliasUserOnMP,
    loadHomePage,
    getMyAccountCredits,
    appLoading
  }
)(AnalyticsAndCommonLogicComponent);
