import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { isKA } from '../../../../../common/config/utils';

const prePriceText = ({
  liveModeMinSalesPrice, liveModeMinPrice, text
}) => {
  if (liveModeMinSalesPrice) {
    return <span className={ classes.crosoutPrice }>{isKA() ? `$${ parseFloat(liveModeMinPrice).toFixed(2) }/min` : `$${ parseFloat(liveModeMinPrice).toFixed(2) }`}</span>;
  }
  return <span className={ classes.minPriceText }>{ text }</span>;
};

function PriceText({
  liveModeMinPrice, liveModeMinSalesPrice, text, staffPick
}) {
  const price = () => parseFloat(liveModeMinSalesPrice || liveModeMinPrice).toFixed(2);
  return (
    <div className={ staffPick ? classes.staffPick : '' }>
      <div className={ isKA() ? classes.textColumnContainer : classes.textContainer }>
        {prePriceText({ liveModeMinSalesPrice, liveModeMinPrice, text })}
        <div className={ classes.priceSeparator } />
        <span style={ liveModeMinSalesPrice ? { color:'#62C124' } : { color:'black' } } suppressHydrationWarning>
          {`$${ price() }/min`}
        </span>
      </div>
    </div>
  );
}

PriceText.propTypes = {
  liveModeMinPrice: PropTypes.string,
  liveModeMinSalesPrice: PropTypes.string,
  loading: PropTypes.bool,
  staffPick: PropTypes.bool,
  text: PropTypes.string
};

PriceText.defaultProps = {
  liveModeMinPrice: null,
  liveModeMinSalesPrice: null,
  loading: false,
  staffPick:false,
  text: null
};

export default PriceText;
