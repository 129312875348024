import queryString from 'query-string';
import { setAplicationError  } from '../actions/aplication_error';
import { getDeviceId } from './device_id';
import {
  fromMobileApp, browserContext, toCamelCase, toSnakeCase,
  isKA
} from '../../common/config/utils';
import { setImpersonated } from '../actions/impersonated';
import { getCurrentLocalization } from './i18n';
import { log } from './app_logger';
import { refreshReducersFromResponse } from './api_functions';

const defaultHeaders = (deviceId) => {
  const deviceHeader = deviceId ? { WEBDEVICE_ID: deviceId } : null;
  return ({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      ...deviceHeader,
      'Language-Code': getCurrentLocalization(),
      WEB_CLIENT: isKA() ? 'KAWeb' : 'PGWeb'
    }
  });
};

const request = (url, {
  opts = {}, ignoreBaseUrl = false, ignorError404, ignoreUserUpdate = false
} = {}) => new Promise(async (resolve, reject) => {
  const deviceId = fromMobileApp ? null : await getDeviceId();
  const apiMethod = opts && opts.method ? opts.method : 'GET';
  log('API', `request started URL: ${ apiMethod } ${ url }; TOKEN: ${ document.querySelector('meta[name="csrf-token"]').getAttribute('content') }`);
  log('API request options:', opts);
  // https://staging.purplegarden.co - save for later use

  const endpoint = ignoreBaseUrl ? url : window.endpoint + url;
  window.fetch(endpoint, {
    ...opts,
    ...defaultHeaders(deviceId),
    credentials: 'include'
  }).then((response) => {
    if (response.ok) {
      if (response.statusText === 'No Content') {
        resolve(toCamelCase({ success: 'ok' }));
        return;
      }
      window.store.dispatch(setImpersonated(response.headers.get('X-Impersonated')));
      response.json().then((responseJson) => {
        log('API', `URL ${ apiMethod } ${ url } request success`);
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.log('response', responseJson);
        }
        const res = toCamelCase(responseJson);
        refreshReducersFromResponse(res, { ignoreUserUpdate });
        resolve(res);
      }).catch((error) => {
        if (response.status === 204) resolve();
        log('API', `URL ${ apiMethod } ${ url } error parsing responce with error ${ error }`);
        reject(error);
      });
      return;
    }
    if (response.status === 401 || response.status === 404 || response.status >= 500) {
      log('API', `URL ${ apiMethod } ${ url } request responce status ${ response.status }`);
      if (ignorError404 && response.status === 404) {
        reject();
        return;
      }
      const { user } = window.store.getState();
      if (user && user.impersonated) {
        response.json().then((res) => {
          window.store.dispatch(setAplicationError({ errorCode: response.status, errorText: res.error, errorUrl: url }));
        });
        return;
      }
      window.store.dispatch(setAplicationError({ errorCode: response.status, errorUrl: url, statusText: response.statusText }));
    }

    log('API', `URL ${ apiMethod } ${ url } response error ${ response.statusText }`);
    reject(response);
  }).catch((error) => {
    log('API', `URL ${ apiMethod } ${ url } request error ${ error }`);
    reject(error);
  });
});

export const loadAdvisorAPI = (slug, params) => {
  log('API', 'loadAdvisorAPI');
  const queryParameters = queryString.stringify(toSnakeCase(params));
  return request(`/mba_s/advisors/${ slug }?${ queryParameters }`);
};

export const loadAdvisorFeedbacksAPI = (id, params) => {
  const {
    positive, negative
  } = toCamelCase(queryString.parse(window.location.search));
  const queryParameters = queryString.stringify(toSnakeCase({
    ...params, positive, negative
  }));
  return request(`/advisors/${ id }/feedbacks?${ queryParameters }`);
};

export const loadFavoriteAfvisorsAPI = () => {
  log('API', 'loadFavoriteAfvisorsAPI');
  return request('/my/favorite_advisors');
};

export const favoriteAdvisorAPI = (advisorId) => {
  log('API', 'favoriteAdvisorAPI');
  return request('/mba/my/favorite_advisors', {
    opts:{
      method: 'POST',
      body: JSON.stringify(toSnakeCase({ advisorId }))
    }
  });
};
export const unFavoriteAdvisorAPI = (advisorId) => {
  log('API', 'unFavoriteAdvisorAPI');
  return request(`/mba/my/favorite_advisors/${ advisorId }`, { opts: { method: 'DELETE' } });
};

export const getPricingOptions = (advisorId, options) => {
  log('API', 'getPricingOptions');
  const params = queryString.stringify(toSnakeCase(options));
  return request(`/advisors/${ advisorId }/pricing_options?${ params }`);
};

export const createChatApi = (pricePerMinute, duration, advisorId) => {
  log('API', 'createChatApi');
  return request('/my/chats', {
    opts: {
      method: 'POST',
      body: JSON.stringify(toSnakeCase({
        advisorId, duration, pricePerMinute
      }))
    }
  });
};

export const loadMyOrdersAPI = (params) => {
  log('API', 'loadMyOrdersAPI');
  return request(`/mba/my/orders?${ queryString.stringify(params) }`);
};

export const loadAdvisorOrdersAPI = (page, advisorID) => {
  log('API', 'loadAdvisorOrdersAPI');
  return request(`/my/orders?advisor_id=${ advisorID }&current_page=${ page }`);
};

export const hangupChatApi = (orderId) => {
  log('API', 'hangupChatApi');
  return request(`/my/orders/${ orderId }`, {
    opts: {
      method: 'DELETE'
    }
  });
};

export const continueChatApi = (orderId, duration) => {
  log('API', 'continueChatApi');
  return request(`/orders/${ orderId }/chats?duration=${ duration }`, {
    opts: {
      method: 'POST'
    }
  });
};

export const loadOrderDetailsAPI = (orderId) => {
  log('API', 'loadOrderDetailsAPI');
  return request(`/my/orders/${ orderId }`);
};

export const logInAPI = (email, password, remeberMe, recaptchaToken) => {
  log('API', 'logInAPI');
  return request('/users/sign_in', {
    opts: {
      method: 'POST',
      body: JSON.stringify({
        user: {
          email,
          password,
          remember_me: remeberMe
        },
        rc_token: recaptchaToken
      })
    }
  });
};

export const registerAPI = (email, password, rememberMe, recaptchaToken) => {
  log('API', 'registerAPI');
  return request('/users', {
    opts:{
      method: 'POST',
      body: JSON.stringify({
        user: {
          email,
          password,
          intention: 'client',
          consent_to_privacy_policy: true,
          remember_me: rememberMe
        },
        rc_token: recaptchaToken
      })
    }
  });
};

export const authSocAPI = ({
  facebookToken, googleToken, rememberMe, consentedToGDPR
}) => {
  log('API', 'authSocAPI');
  return request('/users', {
    opts: {
      method: 'POST',
      body: JSON.stringify({
        user: {
          facebook_token: facebookToken,
          google_id_token: googleToken,
          remember_me: rememberMe,
          consent_to_privacy_policy: consentedToGDPR,
          intention: 'client'
        }
      })
    }
  });
};

export const getCurrentUserAPI = () => {
  log('API', 'getCurrentUserAPI');
  const query = queryString.stringify({ with_verification: true });
  return request(`/my/account?${ query }`);
};

export const updateUserAPI = (payload) => request('/my/account/', {
  opts: {
    method: 'PUT',
    body: JSON.stringify(toSnakeCase({ user:{ ...payload } }))
  }
});

export const checkNickname = nickname => {
  log('API', 'checkNickname');
  const queryParameters = queryString.stringify({ nickname });
  return request(`/my/account/check?${ queryParameters }`);
};

export const introduceYourselfUpdate = ({ nickname, gender, date }) => {
  log('API', 'introduceYourselfUpdate');
  return request('/my/account/', {
    opts: {
      method: 'PUT',
      body: JSON.stringify({ nickname, gender, date_of_birth: date })
    }
  });
};

export const sendOrderReviewAPI = (feedback, orderId) => {
  log('API', 'sendOrderReviewAPI');
  return request(`/my/orders/${ orderId }`, {
    opts: {
      method: 'PUT',
      body: JSON.stringify(toSnakeCase(feedback))
    }
  });
};

export const deleteOrderAPI = (orderId) => {
  log('API', 'deleteOrderAPI');
  return request(`/my/orders/${ orderId }`, {
    opts: {
      method: 'PUT',
      body: JSON.stringify({ delete: true })
    }
  });
};

export const sendOrderMessageAPI = (orderId, authorName, text) => {
  log('API', 'sendOrderMessageAPI');
  return request(`/my/orders/${ orderId }/messages`, {
    opts: {
      method: 'POST',
      body: JSON.stringify(toSnakeCase({ message: { authorName, text } }))
    }
  });
};

export const passwordUpdateAPI = (password, confirmPassword) => {
  log('API', 'passwordUpdateAPI');
  return request('/my/account', {
    opts:{
      method: 'PUT',
      body: JSON.stringify({ user: { password, password_confirmation: confirmPassword } })
    }
  });
};

export const loadNotificationPreferencesAPI = () => {
  log('API', 'loadNotificationPreferencesAPI');
  return request('/my/notification_preferences');
};

export const updateNotificationPreferencesAPI = (preferences) => {
  log('API', 'updateNotificationPreferencesAPI');
  return request('/my/notification_preferences', {
    opts: {
      method: 'PUT',
      body: JSON.stringify(toSnakeCase(preferences))
    }
  });
};

export const logOutAPI = () => {
  log('API', 'logOutAPI');
  return request('/users/logout/', {
    opts: {
      method: 'DELETE'
    }
  });
};

export const getCreditsAPI = () => {
  log('API', 'getCreditsAPI');
  const queryParameters = queryString.stringify({
    with_payment_sources: true, ...browserContext
  });
  return request(`/my/app_credits?${ queryParameters }`);
};

export const createNotifyMeAPI = (advisorId, additionParams) =>  {
  log('API', 'postNotifyMeAPI');
  return request(`/mba/advisors/${ advisorId }/availability_notification`, {
    opts: {
      method: 'POST',
      body: JSON.stringify(toSnakeCase(additionParams))
    }
  });
};

export const deleteNotifyMeAPI = (advisorId) =>  {
  log('API', 'postNotifyMeAPI');
  return request(`/mba/advisors/${ advisorId }/availability_notification`, {
    opts: {
      method: 'DELETE'
    }
  });
};

export const promoCodeAPI = (code) => {
  log('API', 'promoCodeAPI');
  return request('/my/promo_code_redemptions', {
    opts:{
      method: 'POST',
      body: JSON.stringify({ code })
    }
  });
};

export const passwordForgotAPI = (email) => {
  log('API', 'passwordForgotAPI');
  return request('/users/password', {
    opts: {
      method: 'POST',
      body: JSON.stringify({ user: { email } })
    }
  });
};

export const loadTransciptAPI = (orderId) => {
  log('API', 'loadTransciptAPI');
  return request(`/my/orders/${ orderId }/chat_messages`);
};

export const loadCashbackStatusAPI = () => {
  log('API', 'loadCashbackStatusAPI');
  return request('/my/cashbacks/new');
};

export const sendPNTokenToServerAPI = (token) => {
  log('API', 'sendPNTokenToServerAPI');
  return request('/my/account', {
    opts: {
      method: 'PUT',
      body: JSON.stringify({ user: { web_push_token: token } })
    }
  });
};

export const changeAppStateAPI = (orderId, state) => {
  log('API', 'changeAppStateAPI');
  return request(`/my/orders/${ orderId }/client_state?state=${ state }`, {
    opts:{
      method: 'PUT'
    }
  });
};

export const loadAvailablePaymentSourcesApi = () => {
  log('API', 'loadAvailablePaymentSourcesApi');
  const queryParameters = queryString.stringify({ ...browserContext });
  return request(`/my/payment_sources?${ queryParameters }`);
};

export const removePaymentSourceApi = (id) => {
  log('API', 'removePaymentSourceApi');
  return request(`/my/payment_sources/${ id }`, {
    opts:{
      method: 'DELETE'
    }
  });
};

export const addPaymentSourceApi = (method) => {
  log('API', 'addPaymentSourceApi');
  return request('/my/payment_sources', {
    opts: {
      method: 'POST',
      body: JSON.stringify({ method, ...browserContext })
    }
  });
};

export const loadPaymentOptionsApi = (token) => {
  log('API', 'loadPaymentOptionsApi');
  return request('/my/payment_sources/current', {
    opts: {
      method: 'POST',
      body: JSON.stringify({ ...browserContext, rc_token: token })
    }
  });
};

export const editPaymentSourceApi = ({ id, params, type }) => {
  const parameters = JSON.stringify({ ...params, ...browserContext });
  log('API', `${ type }, PARAMETERS: ${ parameters }`);
  return request(`/my/payment_sources/${ id }`, {
    opts: {
      method: 'PUT',
      body: parameters
    }
  });
};

export const getAvatarPresignedURL = () => {
  log('API', 'getAvatarPresignedURL');
  return request('/my/account/upload_configs?asset_type=avatar');
};

export const voipStartCallAPI = (advisorId, duration, ppm, type, tryout) => {
  log('API', 'voipStartCallAPI', advisorId, duration, ppm, type, tryout);
  return request('/my/conversations', {
    opts: {
      method: 'POST',
      body: JSON.stringify({
        advisor_id: advisorId,
        duration,
        price_per_minute: ppm,
        conversation_type: type,
        tryout
      })
    }
  });
};

export const voipContinueCallAPI = (orderId, duration, ppm) => {
  log('API', 'voipContinueCallAPI', orderId, duration, ppm);
  return request(`/orders/${ orderId }/conversations`, {
    opts: {
      method: 'POST',
      body: JSON.stringify({
        duration,
        price_per_minute: ppm
      })
    }
  });
};

export const voipNotifyPeerConnectedAPI = (orderId, conversationId) => {
  log('API', 'voipNotifyPeerConnectedAPI', orderId, conversationId);
  return request(`/orders/${ orderId }/conversations/${ conversationId }/peers`, {
    opts: {
      method: 'PUT'
    }
  });
};

export const voipPullConversationAPI = (orderId, conversationId) => {
  log('API', 'voipPullConversationAPI', orderId, conversationId);
  return request(`/orders/${ orderId }/conversations/${ conversationId }`, {
    opts: {
      method: 'PUT'
    }
  });
};

export const voipTimerEndConversationAPI = (orderId, conversationId) => {
  log('API', 'voipTimerEndConversationAPI', orderId, conversationId);
  return request(`/orders/${ orderId }/conversations/${ conversationId }/timer`, {
    opts: {
      method: 'DELETE'
    }
  });
};

export const voipHangupConversationAPI = (orderId, conversationId) => {
  log('API', 'voipHangupConversationAPI', orderId, conversationId);
  return request(`/orders/${ orderId }/conversations/${ conversationId }`, {
    opts: {
      method: 'DELETE'
    }
  });
};

export const voipForceHangupConversationAPI = (orderId, conversationId) => {
  log('API', 'voipForceHangupConversationAPI', orderId, conversationId);
  return request(`/orders/${ orderId }/conversations/${ conversationId }/force`, {
    opts: {
      method: 'DELETE'
    }
  });
};

export const voipPingAPI = (url, buyerDuration, advisorDuration, sequenceNumber) => {
  log('API', 'voipPingAPI', url, buyerDuration, advisorDuration, sequenceNumber);
  return request(`${ url }?duration_buyer=${ buyerDuration }&duration_advisor=${ advisorDuration }&sequence_number=${ sequenceNumber }`, {
    opts: {
      method: 'POST'
    },
    ignoreBaseUrl: true
  });
};

export const getAdditionalPurchaseInfoFromURL = (URL) => {
  log('API', `getAdditionalPurchaseInfoFromURL ${ URL }`);
  return request(URL, { ignoreUserUpdate: true });
};

export const getUserVerificationsAPI = () => {
  log('API', 'getUserVerificationsAPI');
  return request('/my/user_verifications');
};

export const createUserVerificationAPI = (verification) => {
  log('API', 'createUserVerificationAPI');
  return request('/my/user_verifications', { opts: { method: 'POST', body: JSON.stringify(verification) } });
};

export const getVerificationStatusAPI = (id) => {
  log('API', `getVerificationStatus for id: ${ id }`);
  return request(`/my/user_verifications/${ id }`);
};

export const jumioUploadedAPI = (verification) => {
  const { id } = verification;
  log('API', `jumioUploadedAPI for id: ${ id }`);
  return request(`/my/user_verifications/${ id }`, { opts: { method:'PUT', body: JSON.stringify(verification) } });
};

export const getHomePage = (params) => {
  log('API', 'getHomePage');
  const queryParameters = queryString.stringify(toSnakeCase(params));

  // return request(`/garden/home?${ queryParameters }`);
  return request(`/mba/home?${ queryParameters }`);
};

export const getSearchList = (params) => {
  const {
    includeOffline, minNumberOfReviewsGe, ppmGe, ppmLe, readingVia, query
  } = toCamelCase(queryString.parse(window.location.search));
  const queryParameters = queryString.stringify(toSnakeCase({
    ...params, includeOffline, minNumberOfReviewsGe, ppmGe, ppmLe, readingVia, query
  }));
  return request(`/mba/advisors?${ queryParameters }`);
};

export const getCreditCardProcessingDataApi = ({ url, params }) => {
  const queryParameters = queryString.stringify({ ...params });
  log('API', `getCreditCardProcessingDataApi, PARAMETERS: ${ queryParameters }`);
  return request(`${ url }${ queryParameters }`, { ignoreBaseUrl: true });
};

export const createCreditCardProcessingPurchaseApi = (params) => {
  const parameters = JSON.stringify(toSnakeCase({ ...params }));
  log('API', `createCreditCardProcessingPurchase, PARAMETERS: ${ parameters }`);
  return request('/my/user_credits', { opts: { method: 'POST', body: parameters } });
};

export const getCreditCardProcessingPurchaseResponceApi = (id) => {
  log('API', `getCreditCardProcessingPurchaseResponce ID: ${ id }`);
  return request(`/my/user_credits/${ id }`, { ignoreUserUpdate: true });
};

export const getDirectMessagesApi = (id) => {
  log('API', `getDirectMessagesApi ID: ${ id }`);
  const queryParameters = queryString.stringify(toSnakeCase({ openFrom: 'advisor_dm_email' }));
  return request(`/direct_messages/${ id }?${ queryParameters }`, { ignorError404: true });
};

export const getSEODataAPI = (route) => {
  log('API', `getSEOData for ${ route }`);
  return request(`/seo_data${ route }`, { ignorError404: true });
};

export const getFeesApi = ({ amountToPay }) => {
  log('API', `getFees for ${ amountToPay }`);
  const query = queryString.stringify({ amount_to_pay: amountToPay });
  return request(`/my/app_credits/calculate_fees?${ query }`);
};

export const getActivityApi = (advisorId, filter, paginationParams) => {
  log('API', 'getActivityApi', advisorId, filter, paginationParams);
  const query = queryString.stringify({ ...paginationParams });
  return request(`/my/activity?advisor_id=${ advisorId }&show_only=${ filter }&${ query }`);
};

export const createActivityMessage = (advisorId, message) => {
  log('API', 'createActivityMessage', advisorId, message);
  return request(`/advisors/${ advisorId }/feed_messages`, {
    opts: {
      method: 'POST',
      body: JSON.stringify({
        message_kind: message,
        content: {
          message
        }
      })
    }
  });
};

export const validateCreditCardApi = () => {
  log('API', 'validateCreditCard');
  return request('/my/payment_validations', { opts: { method: 'POST' } });
};

export const getValidateCreditCardStatusApi = (id) => {
  log('API', 'getValidateCreditCardStatusApi');
  return request(`/my/payment_validations/${ id }`);
};

export const getMyAccountCreditsApi = (sequence) => {
  log('API', 'getMyAccountCreditsApi');
  return request(`/my/account/credits?sequence=${ sequence }`);
};
