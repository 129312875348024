import {
  getCreditCardProcessingDataApi,
  createCreditCardProcessingPurchaseApi,
  getCreditCardProcessingPurchaseResponceApi
} from '../config/api';
import Const from '../config/const';

const creditCardProcessingLoading = () => ({
  type: Const.creditCardProcessing.loading
});

const creditCardProcessingDataloaded = (result) => ({
  type: Const.creditCardProcessing.dataLoaded,
  payload: result
});

const createCreditCardProcessingPurchaseLoaded = (result) => ({
  type: Const.creditCardProcessing.purchaseLoaded,
  payload: result
});

const getCreditCardProcessingPurchaseResponceLoaded = (result) => ({
  type: Const.creditCardProcessing.purchaseResponceLoaded,
  payload: result
});

const creditCardProcessingError = (error) => ({
  type: Const.creditCardProcessing.error,
  error
});

const clearCreditCardProcessingAction = () => ({
  type: Const.creditCardProcessing.clearCreditCardProcessing
});

export const getCreditCardProcessingData = ({ url, params }) => (dispatch) => {
  dispatch(creditCardProcessingLoading());
  getCreditCardProcessingDataApi({ url, params })
    .then((result) => {
      dispatch(creditCardProcessingDataloaded(result));
    })
    .catch((error) => {
      error.json()
        .then((jsonError) => { dispatch(creditCardProcessingError(jsonError)); })
        .catch(() => { dispatch(creditCardProcessingError(error)); });
    });
};

export const createCreditCardProcessingPurchase = (params) => (dispatch) => {
  createCreditCardProcessingPurchaseApi(params)
    .then((result) => {
      dispatch(createCreditCardProcessingPurchaseLoaded(result));
    })
    .catch((error) => {
      error.json()
        .then((jsonError) => { dispatch(creditCardProcessingError(jsonError)); })
        .catch(() => { dispatch(creditCardProcessingError(error)); });
    });
};

export const getCreditCardProcessingPurchaseResponce = (id) => (dispatch) => {
  getCreditCardProcessingPurchaseResponceApi(id)
    .then((result) => {
      dispatch(getCreditCardProcessingPurchaseResponceLoaded(result));
    })
    .catch((error) => {
      error.json()
        .then((jsonError) => { dispatch(creditCardProcessingError(jsonError)); })
        .catch(() => { dispatch(creditCardProcessingError(error)); });
    });
};

export const clearCreditCardProcessing = () => (dispatch) => {
  dispatch(clearCreditCardProcessingAction());
};
