import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { HomePageAdvisorLiveModes } from '../../prop_types';
import PriceText from '../price_text/price_text';

import { chatOnline } from './img/chat_online';
import { chatOffline } from './img/chat_offline';
import { voiceOnline } from './img/voice_online';
import { voiceOffline } from './img/voice_offline';
import { videoOnline } from './img/video_online';
import { videoOffline } from './img/video_offline';
import { isPrerenderMode } from '../../../../config/util';

const modeIcons = {
  chat_online: chatOnline,
  chat_offline: chatOffline,
  chat_busy: chatOnline,
  voice_online: voiceOnline,
  voice_offline: voiceOffline,
  voice_busy: voiceOnline,
  video_online: videoOnline,
  video_busy: videoOnline,
  video_offline: videoOffline
};

function AdvisorModes({
  liveModeMinPrice, liveModes, staffPick, liveModeMinSalesPrice, text, loading
}) {
  const renderMode = (liveMode) => {
    const { modeState, mode } = liveMode;
    if (modeState === 'disabled') return null;
    return (
      <div className={ classes.modeIconContainer } key={ mode }>
        <div className={ classes.modeIcon } key={ mode }>
          { modeIcons[`${ mode }_${ modeState }`] }
        </div>
      </div>
    );
  };

  const renderModes = () => {
    if (isPrerenderMode() || loading || liveModes.length === 0) return <div className={ classes.modesHolderSkeleton } />;
    return liveModes.map((mode) => renderMode(mode));
  };

  return (
    <div className={ staffPick ? classes.staffPick : classes.preview }>
      <div className={ classes.mainContainer }>
        <div className={ classes.modesHolder }>
          { renderModes() }
        </div>
        <PriceText liveModeMinPrice={ liveModeMinPrice } liveModeMinSalesPrice={ liveModeMinSalesPrice } text ={ text } staffPick={ staffPick } />
      </div>
    </div>
  );
}

AdvisorModes.propTypes = {
  liveModeMinPrice: PropTypes.string,
  liveModeMinSalesPrice: PropTypes.string,
  liveModes: PropTypes.arrayOf(PropTypes.shape(HomePageAdvisorLiveModes)),
  staffPick: PropTypes.bool,
  text: PropTypes.string,
  loading: PropTypes.bool
};

AdvisorModes.defaultProps = {
  liveModeMinPrice: null,
  liveModes: [],
  liveModeMinSalesPrice: null,
  staffPick: false,
  text: null,
  loading: false
};

export default AdvisorModes;
