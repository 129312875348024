import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import classes from '../classes.module.scss';
import RateSection from '../rate_section';
import RateReview from '..';
import withPGTranslation from '../../../config/withPGTranslation';
import AppGeneralPopup from '../../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../../common/config/const';
import { rateReviewTheme } from '../../../config/const';
import { isMobile } from '../../../config/util';
import { trackPageVisit } from '../../../actions/analytics';

const starIconImageSource = require('../img/chat_rate_star.png');

function RateReviewAfterChat({
  visible,
  onCancel,
  orderId,
  advisorName,
  t,
  clickSource,
  advisorId,
  profileLanguage,
  createdAt,
  redirectToUrl,
  chatType
}) {
  const [like, setLike] = useState(null);
  const [showSmallModal, setShowSmallModal] = useState(false);
  const [showFullModal, setShowFullModal] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    setShowSmallModal(visible);
    if (visible) trackPageVisit('live session ended review draw');
  }, []);

  function redirectTo() {
    history(redirectToUrl);
  }

  const onSkipClick = () => {
    setLike(null);
    setShowSmallModal(false);
    onCancel();
    if (redirectToUrl) redirectTo();
  };

  const renderSkipButton = () => (
    <div className={ classes.skipTopButtonContainer }>
      <button type ="button" className={ classes.skipTopButton } onClick={ onSkipClick }>
        { t('rate_review.skip') }
      </button>
    </div>
  );

  const fullModalOnCancel  = () => {
    setShowFullModal(false);
    onCancel();
  };

  const renderFullModal = () => (
    <RateReview
      visible={ showFullModal }
      onCancel={ fullModalOnCancel }
      existingLike={ like }
      advisorName={ advisorName }
      orderId={ orderId }
      advisorId={ advisorId }
      profileLanguage={ profileLanguage }
      createdAt={ createdAt }
      clickSource={ clickSource }
      chatType={ chatType }
    />
  );

  const renderSmallModalLogiImg = () => (
    <img
      alt=""
      src={ starIconImageSource }
      className={ classes.starImage }
    />
  );

  const renderSmallModalContent = () => (
    <div className={ classes.smallModalContentContainer }>
      <RateSection
        value={ like }
        onSmallModal
        onValueChanged={ (liked) => {
          setLike(liked);
          setShowSmallModal(false);
          setShowFullModal(true);
        } }
      />
      { renderSkipButton() }
    </div>
  );

  const renderSmallModal = () => (
    <AppGeneralPopup
      visible={ showSmallModal }
      logoImg={ renderSmallModalLogiImg() }
      theme={ appGeneralPopupTheme.BOTTOM }
    >
      { renderSmallModalContent() }
    </AppGeneralPopup>
  );

  const renderMobileView = () => (
    <div>
      {renderSmallModal()}
      {renderFullModal()}
    </div>
  );

  const onCloseButtonAction = () => {
    onCancel();
  };

  const renderBrowserViewFull = () => (
    <AppGeneralPopup
      closeButtonAction={ onCloseButtonAction }
      visible={ visible }
    >
      <RateReview
        visible={ visible }
        onCancel={ onCancel }
        existingLike={ like }
        advisorName={ advisorName }
        orderId={ orderId }
        advisorId={ advisorId }
        profileLanguage={ profileLanguage }
        createdAt={ createdAt }
        clickSource={ clickSource }
        theme={ rateReviewTheme.CHAT_ENDED }
        chatType={ chatType }
      />
    </AppGeneralPopup>
  );

  const renderBrowserView = () => (
    <>
      { renderBrowserViewFull() }
    </>
  );

  return (
    isMobile() ? renderMobileView() : renderBrowserView()
  );
}

RateReviewAfterChat.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  existingComment: PropTypes.string,
  existingLike: PropTypes.bool,
  orderId: PropTypes.number.isRequired,
  advisorName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  clickSource: PropTypes.string,
  advisorId: PropTypes.number,
  profileLanguage: PropTypes.string,
  createdAt: PropTypes.string,
  redirectToUrl: PropTypes.string,
  chatType: PropTypes.string
};

RateReviewAfterChat.defaultProps = {
  existingComment: '',
  existingLike: null,
  clickSource: null,
  advisorId: null,
  profileLanguage: null,
  createdAt: null,
  redirectToUrl: null,
  chatType: null
};

export default withPGTranslation(RateReviewAfterChat);
