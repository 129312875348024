import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import classes from './classes.module.scss';
import Rating from '../../../advisor_avatar/rating/rating';
import AdvisorCTA from '../advisor_cta';
import AdvisorProfileAvatar from '../../../advisor_profile_avatar';
import { AdvisorPropType, AdvisorDefaultProps } from '../../../../reducers/advisor_prop_types';
import ReviewItem, { reviewItemThemes } from '../reviews_items';
import TopIcons from '../../../advisor_profile_avatar/top_icons';
import { isAdvisorBusy, getReviewCount, isPrerenderMode } from '../../../../config/util';
import ReadingSinceAndCountItem, { readingSinceAndCountItemThemes } from '../readingSinceAndCountItem';
import { advisorHeaderTheme } from '../../../../config/themes';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { isKA } from '../../../../../common/config/utils';

const shouldRenderReviews = (likesCount, dislikesCount) => likesCount > 0 || dislikesCount > 0;

const reviewItemsContent = (likesCount, dislikesCount, t) => (shouldRenderReviews(likesCount, dislikesCount)
  ? [<ReviewItem key="positive" value={ likesCount } isPositive theme={ reviewItemThemes.advisorHeader } />,
    <ReviewItem key="negative" value={ dislikesCount } isPositive={ false } theme={ reviewItemThemes.advisorHeader } />]
  : <div className={ classes.noReviews }>{t('advisor_profile.no_reviews')}</div>);

const renderBusyLable = ({ liveModes, t }) => {
  if (!isAdvisorBusy({ liveModes })) return null;
  return (
    <div className={ classes.busyLableContainer }>
      { t('advisor_profile.advisor_busy') }
    </div>
  );
};

const advisorCTAs = ({
  loading, liveModes, id, nickname, profilePictureUrl, t, advisorXfmProgram, userXfmProgram
}) => {
  if (loading || isPrerenderMode() || liveModes.length === 0) {
    const skeletonCount = isKA() ? 2 : 3;
    const skeletonClassName = isKA() ? classNames(classes.ctaSkeleton, classes.ctaSkeletonFullWidth) : classes.ctaSkeleton;
    return (
      <div className={ classes.ctaSkeletonContainer }>
        {
          [...Array(skeletonCount)].map((s, index) => (
            <div id={ `ctaSkeleton${ index }` } key={ `ctaSkeleton${ index }` } className={ skeletonClassName } />
          ))
        }
      </div>
    );
  }
  return (
    <div className = { classes.advisorCTAs }>
      {
        liveModes.map((mode) => (
          <AdvisorCTA
            key={ mode.mode }
            { ...mode }
            advisorId={ id }
            advisorCallProps={ {
              id,
              name: nickname,
              profilePictureUrl
            } }
            liveModes={ liveModes }
            advisorXfmProgram={ advisorXfmProgram }
            userXfmProgram={ userXfmProgram }
            needCheckAction
          />
        ))
      }
      {renderBusyLable({ liveModes, t })}
    </div>
  );
};

const renderAdvsiorTitleContent = ({ nickname, serviceTitle }) => (
  <div className={ classes.advisorTitleContainer } itemProp="author" itemScope itemType="http://schema.org/Person">
    <div className={ classes.advisorTitle }>
      <ExpandedText
        customClasses={ { text: classes.advisorTitleText } }
        ellipsis
      >
        <h1 className={ classes.advisorTitleText } itemProp="name" suppressHydrationWarning>
          { nickname || '' }
        </h1>
      </ExpandedText>
    </div>
    <div className={ classes.advisorSubtitle }>
      <ExpandedText
        customClasses={ { text: classes.advisorSubtitleText } }
        ellipsis
      >
        <div suppressHydrationWarning>
          { serviceTitle || '' }
        </div>
      </ExpandedText>
    </div>
  </div>
);

const renderAdvsiorTitle = ({
  nickname, serviceTitle, likesCount, dislikesCount, rating
}) => {
  const reviewCount = getReviewCount({ likesCount, dislikesCount });
  if (reviewCount > 0 && rating > 0) {
    return (
      <div itemProp="review" itemScope itemType="https://schema.org/Review" suppressHydrationWarning>
        { renderAdvsiorTitleContent({ nickname, serviceTitle }) }
      </div>
    );
  }
  return (
    <div suppressHydrationWarning>
      { renderAdvsiorTitleContent({ nickname, serviceTitle }) }
    </div>
  );
};

function AdvisorHeader({
  nickname, serviceTitle, rating, liveModes, id, profilePictureUrl, readingsCount, yearJoined,
  likesCount, dislikesCount, t, favorite, isNewAdvisor, loading, availabilityNotification,
  favoriteAdvisor, theme, serviceDescription, advisorXfmProgram, userXfmProgram, status
}) {
  const reviewsItems = () => (
    <div className={ classes.reviewsContainer } suppressHydrationWarning>
      {reviewItemsContent(likesCount, dislikesCount, t) }
    </div>
  );

  const readingsStats = () => {
    if (loading) return <Skeleton height={ 55 } className={ classes.advsiorReadingsContainer } />;
    return (
      <div className={ classes.advsiorReadingsContainer }>
        <ReadingSinceAndCountItem
          readingsTitleKey="advisor_profile.readings_since"
          readingsCount={ readingsCount }
          yearJoined={ yearJoined }
          theme={ readingSinceAndCountItemThemes.default }
          loading={ loading }
        />
        {reviewsItems()}
      </div>
    );
  };

  const renderAboutMyServices = () => (
    <div className={ classes.aboutMyServicesContainer }>
      <div className={ classes.aboutMyServicesTitle }>
        { t('advisorProfile.about_my_services') }
      </div>
      <ExpandedText
        customClasses={ {
          text: classes.aboutMyServicesText,
          readMoreBtn: classes.aboutMyServicesReadMoreBtn
        } }
        skeleton={ <Skeleton className={ classes.ctaSkeleton } /> }
        itemProp="description"
        loading={ loading }
        readMoreKind="limitTextByRows"
        limit={ 4 }
      >
        <div suppressHydrationWarning>
          { serviceDescription || ''}
        </div>
      </ExpandedText>
    </div>
  );

  const renderAdvisorModes = () => {
    if (theme === advisorHeaderTheme.terminatedAdvisor) return renderAboutMyServices();
    return advisorCTAs({
      loading, liveModes, id, nickname, profilePictureUrl, t, advisorXfmProgram, userXfmProgram
    });
  };

  const renderAdvisorHeader = () => (
    <div className={ classes.advisorHeaderContainer }>
      <div className={ classes.avatarHolder }>
        <AdvisorProfileAvatar advisorId={ id } theme={ theme } loading={ loading } />
      </div>
      <div className={ classes.advisorInfo }>
        <div className={ classes.basicInfoContainer }>
          <div className={ classes.advsiorTitleContainer }>
            { renderAdvsiorTitle({
              nickname, serviceTitle, likesCount, dislikesCount, rating
            })}
          </div>
          <div className={ classes.topIconsAndRatingContainer }>
            <TopIcons
              id = { id }
              favoriteAdvisor = { favoriteAdvisor }
              favorite = { favorite }
              loader = { loading }
              theme={ theme }
              availabilityNotification={ availabilityNotification }
              advisorStatus={ status }
            />
            <div className={ classes.ratingHolder }>
              <Rating
                rating={ rating }
                isNewAdvisor={ isNewAdvisor }
                needAddItemProp
                likesCount={ likesCount }
                dislikesCount={ dislikesCount }
              />
            </div>
          </div>
        </div>
        <div className={ classes.advisorModesContainer } id="advisorModesContainer">
          { renderAdvisorModes() }
        </div>
        {readingsStats()}
      </div>
    </div>
  );

  return (
    <div className={ classes[theme] }>
      { renderAdvisorHeader() }
    </div>
  );
}

AdvisorHeader.propTypes = {
  ...AdvisorPropType,
  onNotifyClick: PropTypes.func,
  badges:PropTypes.array,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string,
  advisorXfmProgram: PropTypes.object,
  userXfmProgram: PropTypes.object
};

AdvisorHeader.defaultProps = {
  ...AdvisorDefaultProps,
  badges: [],
  onNotifyClick: null,
  theme: advisorHeaderTheme.default,
  advisorXfmProgram: {},
  userXfmProgram: {}
};

export default AdvisorHeader;
