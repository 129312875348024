import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import env from '../../config/variables';
import {
  convertErrorArrayToString, passwordIsValid
} from '../../config/util';
import PromoCodeSignUp from '../promo_code_sign_up';
import Social from './inner_components/social';
import classes from './classes.module.scss';
import { log } from '../../config/app_logger';
import Const from '../../config/const';
import ForgotPassword  from '../forgot_password';
import LogIn from './inner_components/log_in';
import SignUp from './inner_components/sign_up';
import { appRoutes, needRegisterRoutes } from '../../config/app_routes';
import {
  hideBodyScroll, unlockBodyScroll, emailIsValid, withCaptcha, matchPaths, isKA
} from '../../../common/config/utils';
import { dissmissPopup } from '../../actions/general_popup';
import Spiner from '../../../common/helpers/spiner';
import Alert from '../../../common/helpers/alert';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import { getCurrentLocalization } from '../../config/i18n';
import { appLogo } from '../../logo/logo';
import { getImageByEnvironment } from '../../project_items/image_renderer/image_renderer';

const { shared } = window;

const { IN_EU, MATCH_UP_BONUS_INFO } = env;

const backArrowPG = require('./img/register_back_arrow_pg.svg');
const backArrowKA = require('./img/register_back_arrow_ka.svg');

const isAffiliatePromo = ({ userMatchUpBonusInfo, userShowMatchUpBonusInfo }) => {
  const creditMatchingInfo = userMatchUpBonusInfo || MATCH_UP_BONUS_INFO;
  return !!(creditMatchingInfo
    && Object.keys(creditMatchingInfo).length !== 0
    && (userShowMatchUpBonusInfo === null || userShowMatchUpBonusInfo));
};

class Registration extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    logIn: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    authSocial: PropTypes.func.isRequired,
    authPendingAction: PropTypes.func,
    authPendingCloseAction: PropTypes.func,
    t: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    displayRegister: PropTypes.func.isRequired,
    signUpFlow: PropTypes.string,
    currentScreen: PropTypes.string.isRequired,
    screenStack: PropTypes.array.isRequired,
    changeScreen: PropTypes.func.isRequired,
    navigationPop: PropTypes.func.isRequired,
    authComplete: PropTypes.bool,
    loadHomePage: PropTypes.func.isRequired,
    clearAffiliatePromoPopup: PropTypes.func.isRequired,
    authClearPendingAction: PropTypes.func.isRequired,
    authErrorActionCreator: PropTypes.func.isRequired,
    gdprSwitcherValueChanged: PropTypes.func.isRequired,
    consentedToGDPR: PropTypes.bool,
    shouldShowGDPRConsent: PropTypes.func.isRequired,
    userMatchUpBonusInfo: PropTypes.object,
    userShowMatchUpBonusInfo: PropTypes.bool,
    updateCurrentScreen: PropTypes.func.isRequired
  };

  static defaultProps = {
    error: null,
    authPendingAction: null,
    authPendingCloseAction: null,
    visible: false,
    signUpFlow: 'menu',
    authComplete: false,
    consentedToGDPR: false,
    userMatchUpBonusInfo: null,
    userShowMatchUpBonusInfo: null
  };

  state = {
    email: '',
    password: '',
    confirmEmail: '',
    rememberMe: false
  };

  componentDidUpdate(prevProps) {
    const {
      authComplete,
      authPendingAction,
      visible,
      currentScreen,
      userMatchUpBonusInfo,
      userShowMatchUpBonusInfo,
      updateCurrentScreen

    } = this.props;

    if (currentScreen === Const.authScreens.signUp && isAffiliatePromo({ userMatchUpBonusInfo, userShowMatchUpBonusInfo })) {
      updateCurrentScreen(Const.authScreens.signUpAffiliatePromo);
    }

    if (visible && !prevProps.visible) {
      hideBodyScroll();
    }

    if (!visible &&  prevProps.visible) {
      unlockBodyScroll();
    }

    if (authComplete && !prevProps.authComplete) {
      if (authPendingAction) {
        authPendingAction();
        return;
      }
      this.redirectToPsychics(true);
    }
  }

  checkInputErrors = () => {
    const { email, confirmEmail, password } = this.state;
    const { authErrorActionCreator, t, currentScreen } = this.props;
    if (!passwordIsValid(password, true)) {
      authErrorActionCreator({ password: [t('error.registration_please_enter_your_password')] });
    }
    if (
      (currentScreen === Const.authScreens.signUp
        || currentScreen === Const.authScreens.signUpAffiliatePromo)
      && email !== confirmEmail) {
      authErrorActionCreator({ email: [t('error.registration_email_not_much')] });
    }
    if (!emailIsValid(email)) {
      authErrorActionCreator({ email: [t('error.registration_please_enter_a_valid_email_address')] });
    }
  };

  onFieldChanged = e => {
    const { error, clearError } = this.props;
    if (error) clearError();
    this.setState({ [e.target.id]: e.target.value });
  };

  onCancelClicked = () => {
    const {
      currentScreen, screenStack, navigationPop, authClearPendingAction,
      authPendingCloseAction, clearError
    } = this.props;

    if (currentScreen === Const.authScreens.signUpAffiliatePromo) {
      authClearPendingAction();
    }

    if (screenStack.length <= 0) {
      dissmissPopup();
      authClearPendingAction();
      if (authPendingCloseAction) {
        authPendingCloseAction();
      }
    }

    if (screenStack.length >= 1) {
      navigationPop();
      clearError();
      return;
    }

    this.redirectToPsychics();
    clearError();
  };

  redirectToPsychics = (authCompleteSusses) => {
    const localization = getCurrentLocalization();
    const {
      loadHomePage,
      displayRegister
    } = this.props;

    if (window.location.pathname === '/' || window.location.pathname === `/${ localization }`) {
      displayRegister({ shouldDisplay: false });
      loadHomePage(true);
      return;
    }

    if (window.location.pathname === '/signin' || window.location.pathname === `/${ localization }/signin`
        || window.location.pathname === '/register' || window.location.pathname === `/${ localization }/register`) {
      window.location.replace(`/${ localization }`);
      return;
    }

    const needRegisterRoutesArray = Object.keys(needRegisterRoutes).map(
      (key) => needRegisterRoutes[key]
    );
    const pathNameWithoutLocalization = window.location.pathname.replace(/(en[/])|(es[/])/, '');
    const needRegisterUrl = needRegisterRoutesArray.find((item) => {
      const match = matchPaths({ pathname: `${ item }`, path: `${ pathNameWithoutLocalization }` });
      if (match && !authCompleteSusses) {
        window.location.replace(appRoutes.psychics);
        return true;
      }
      return false;
    });
    if (!needRegisterUrl) {
      displayRegister({ shouldDisplay: false });
    }
  };

  onLoginClicked = () => {
    log('Register', 'on login');
    const { logIn, signUpFlow } = this.props;
    const { email, password, rememberMe } = this.state;
    withCaptcha('login', (token) => logIn(email, password, rememberMe, signUpFlow, token));
  };

  onRegisterClicked = () => {
    log('Register', 'on register');
    const { register, signUpFlow } = this.props;
    const { email, password, rememberMe } = this.state;
    withCaptcha('login', (token) => register(email, password, rememberMe, signUpFlow, token));
  };

  onOkClickSignUpPromoRead = () => {
    const { displayRegister } = this.props;
    displayRegister({ shouldDisplay: false });
  };

  renderSignUpPromoRead = () => (
    <PromoCodeSignUp
      onOkClickSignUpPromoRead = { this.onOkClickSignUpPromoRead }
    />
  );

  renderGeneralError = () => {
    const { error, clearError } = this.props;

    if (error && (error.hasOwnProperty('email') || error.hasOwnProperty('password'))) return null;

    const errorString = error && convertErrorArrayToString(
      Object.keys(error).map((key) => error[key])
    );
    log('Register', `on error: ${ errorString }`);
    return (
      <Alert
        errorString={ errorString }
        onClose={ clearError }
      />
    );
  };

  navigateToSignIn = () => {
    const { changeScreen } = this.props;
    changeScreen(Const.authScreens.signIn);
    this.screenChanged();
  };

  navigateToSignUp = () => {
    const { changeScreen } = this.props;
    changeScreen(Const.authScreens.signUp);
    this.screenChanged();
  };

  navigateToForgotPassword = () => {
    const { changeScreen } = this.props;
    changeScreen(Const.authScreens.forgotPassword);
  };

  screenChanged = () => {
    const { clearError, gdprSwitcherValueChanged } = this.props;
    gdprSwitcherValueChanged(false);
    clearError();
  };

  facebookResponse = facebookToken => {
    const { rememberMe } = this.state;
    const { authSocial, signUpFlow } = this.props;
    authSocial({ facebookToken, rememberMe }, signUpFlow);
  };

  googleResponse = googleToken => {
    const { rememberMe } = this.state;
    const { authSocial, signUpFlow } = this.props;
    authSocial({ googleToken, rememberMe }, signUpFlow);
  };

  renderSocial = () => {
    const {
      clearError, error, consentedToGDPR, shouldShowGDPRConsent,
      gdprSwitcherValueChanged
    } = this.props;
    return (
      <Social
        facebookCallback={ this.facebookResponse }
        googleCallback={ this.googleResponse }
        inEu={ IN_EU }
        signUpSwitcherChecked={ consentedToGDPR }
        clearError={ clearError }
        error={ error }
        gdprSwitcherValueChanged={ gdprSwitcherValueChanged }
        shouldShowGDPRConsent={ shouldShowGDPRConsent }
      />
    );
  };

  onRemeberMeCheckedChanged = (e) => {
    this.setState({ rememberMe: e.target.checked });
  };

  renderRememberMe = () => {
    const { rememberMe } = this.state;
    const { t, currentScreen } = this.props;
    return (
      <div className={ classes.rememberMeContainer }>
        <input type="checkbox" checked={ rememberMe } onChange={ this.onRemeberMeCheckedChanged } />
        <span className={ classes[`remeberMeMargin${ currentScreen }`] }>{t('remember_me')}</span>
      </div>
    );
  };

  changePrivacySwitcherValue = (value) => {
    const { gdprSwitcherValueChanged } = this.props;
    gdprSwitcherValueChanged(value);
  };

  renderContent = () => {
    const {
      currentScreen, loading, error, t, changeScreen, clearError,
      consentedToGDPR, shouldShowGDPRConsent
    } = this.props;
    const {
      email, password, confirmEmail
    } = this.state;
    switch (currentScreen) {
      case Const.authScreens.signIn:
        return (
          <LogIn
            email={ email }
            password={ password }
            loading={ loading }
            error={ error }
            t={ t }
            onFieldChanged={ this.onFieldChanged }
            rememberMe={ this.renderRememberMe() }
            social={ this.renderSocial() }
            changeScreen={ changeScreen }
            onLogin={ this.onLoginClicked }
            checkInputErrors={ this.checkInputErrors }
            clearError={ clearError }
          />
        );
      case Const.authScreens.signUp:
      case Const.authScreens.signUpAffiliatePromo:
        return (
          <SignUp
            email={ email }
            password={ password }
            confirmEmail={ confirmEmail }
            switcherChecked={ consentedToGDPR }
            shouldShowGDPRConsent={ shouldShowGDPRConsent }
            loading={ loading }
            error={ error }
            t={ t }
            onRegister={ this.onRegisterClicked }
            onFieldChanged={ this.onFieldChanged }
            rememberMe={ this.renderRememberMe() }
            social= { this.renderSocial() }
            onSwitcherChange= { this.changePrivacySwitcherValue }
            changeScreen={ changeScreen }
            checkInputErrors={ this.checkInputErrors }
            clearError={ clearError }
            theme={ currentScreen }
          />
        );
      case Const.authScreens.promoRead:
        return this.renderSignUpPromoRead();
      case Const.authScreens.forgotPassword:
        return <ForgotPassword />;
      default:
        return null;
    }
  };

  renderBackButton = () => {
    const { currentScreen } = this.props;
    if (currentScreen !== Const.authScreens.promoRead) {
      return (
        <div className={ classes.cancelContainer }>
          <button
            type="button"
            onClick={ this.onCancelClicked }
            className={ classes.cancelButton }
          >
            <img className={ classes.cancelButtonImg } src={ isKA() ?  backArrowKA : backArrowPG } alt="" />
          </button>
        </div>
      );
    }
    return null;
  };

  renderTopLogo = () => {
    const { currentScreen } = this.props;
    if (currentScreen !== Const.authScreens.signUpAffiliatePromo) return null;
    return (
      <div
        className={ isMobile ? classes.topLogoContainerMobile : classes.topLogoContainerBrowser }
      >
        <img src={ appLogo() } alt="" className={ classes.topLogoImg } />
      </div>
    );
  };

  renderLogo = () => {
    const { currentScreen } = this.props;
    if (currentScreen === Const.authScreens.signUpAffiliatePromo) return null;
    return (
      <div className={ classes.logoContainer }>
        <img src={ this.getLogo() } alt="" className={ classes.logoImg } />
      </div>
    );
  };

  renderLoading = () => {
    const { loading } = this.props;
    if (loading) return <Spiner loaderColor={ shared.appColor } />;
    return null;
  };

  getLogo = () => {
    const { currentScreen } = this.props;
    return currentScreen === Const.authScreens.promoRead ? getImageByEnvironment('signup_complete_checkmark') : appLogo();
  };

  getHeight = () => {
    const { currentScreen } = this.props;
    if (currentScreen === Const.authScreens.signUpAffiliatePromo) return '100%';
    return '';
  };

  getBackgroundColor = () => {
    const { currentScreen } = this.props;
    if (currentScreen !== Const.authScreens.signUpAffiliatePromo) return '#FFFFFF';
    return '#1b1945';
  };

  render() {
    const { visible } = this.props;
    return (
      <AppGeneralPopup
        visible={ visible }
        logoImg={ this.renderTopLogo() }
        customTopHeader={ this.renderBackButton() }
        theme={ appGeneralPopupTheme.REGISTER }
      >
        <div className={ classes.popover }>
          <div
            className={ isMobile ? classes.mobileContainer : classes.browserContainer }
            style={ { '--backgroundColor': this.getBackgroundColor() } }
          >
            <div className={ classes.contentHolder }>
              <div style={ { zIndex: 9 } }>
                { this.renderLogo() }
                { this.renderContent() }
              </div>
              {this.renderGeneralError()}
            </div>
            {this.renderLoading()}
          </div>
        </div>
      </AppGeneralPopup>
    );
  }
}

export default Registration;
