import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import AdvisorPreview from '../home_page/nested_views/advisor_preview';
import classes from './classes.module.scss';
import { advisorsEmptyList, filterIsApplied, isHomePage } from '../../config/util';
import SeeMoreBtn from './see_more_btn';
import PromotionLabel from '../home_page/nested_views/promotion_label';

const clearFiltersIcon = require('./img/clear_filters.svg');

const cleanFiltersItem = (isLastPage, t, clearFilters, advisors) => {
  if (!filterIsApplied({ withSearch: true })) return null;
  if (isLastPage || (advisors || []).length === 0) {
    return (
      <div className={ classes.filterButtonContainer }>
        <span className={ classes.noFilterTitle }>
          { t('home_page.did_not_find_what_you_wer_looking_for') }
          <br />
          { t('home_page.clear_your_search_filters') }
        </span>
        <button type="button" className={ classes.bottomButton } onClick={ clearFilters }>
          <img alt="" src={ clearFiltersIcon } className={ classes.clearFilterIcon } />
          { t('home_page.clear_filters') }
        </button>
      </div>
    );
  }
  return null;
};

const shouldRenderAdvisorsList = (salesBanner, windowInnerWidth) => (
  !(salesBanner || {}).content
  || windowInnerWidth <= window.shared.phoneOnlyUpSize
  || isHomePage()
);

const renderAdvisorsList = (loading, advisors, salesBanner, windowInnerWidth, renderAdvisorListSection) => {
  const loadingAdvisors = loading ? advisorsEmptyList(5, true) : [];
  const advisorsList = [...advisors, ...loadingAdvisors];
  if (shouldRenderAdvisorsList(salesBanner, windowInnerWidth)) {
    return (
      <div className={ classes.advisorsContainer }>
        { advisorsList.map((advisor, index) => (renderAdvisorListSection(advisor, index))) }
      </div>
    );
  }
  return (
    <div>
      <div className={ classes.advisorsContainer }>
        { advisorsList.slice(0, 4).map((advisor, index) => (
          renderAdvisorListSection(advisor, index))) }
      </div>
      <div className={ classes.promotionHolder }>
        <PromotionLabel />
      </div>
      <div className={ classes.advisorsContainer }>
        { advisorsList.slice(4).map((advisor, index) => (
          renderAdvisorListSection(advisor, index))) }
      </div>
    </div>
  );
};

function AdvisorsList({
  advisors, theme, isLastPage, loadAdvisors, loading, analytics, params, path, t, slug,
  lastPageNum, seenPages, page, salesBanner
}) {
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const history = useNavigate();

  const handleResize = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderAdvisorListSection = (advisor, index) => (
    <AdvisorPreview
      key={ !loading ? advisor.id : `emptyAdvisorSection_${ index }` }
      advisor ={ advisor }
      theme={ theme }
      advisorCount={ 4 }
      loading={ advisor.loading }
      analytics={ analytics }
      sectionSlug={ slug }
    />
  );

  const clearFilters = () => {
    history(`${ window.location.pathname }`, { state: { needRefresh: true }, replace: true });
  };
  return (
    <div className={ classes[`${ theme }`] }>
      { renderAdvisorsList(loading, advisors, salesBanner, windowInnerWidth, renderAdvisorListSection) }
      { cleanFiltersItem(isLastPage, t, clearFilters, advisors) }
      <SeeMoreBtn
        isLastPage={ isLastPage }
        loading={ loading }
        loadAdvisors={ loadAdvisors }
        theme={ theme }
        slug={ slug }
        analytics={ analytics }
        params={ params }
        path={ path }
        lastPageNum={ lastPageNum }
        seenPages={ seenPages }
        page={ page }
      />
    </div>
  );
}

AdvisorsList.propTypes = {
  advisors: PropTypes.array,
  theme: PropTypes.string,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadAdvisors: PropTypes.func,
  isLastPage: PropTypes.bool,
  page: PropTypes.number,
  analytics: PropTypes.object,
  params: PropTypes.object,
  path: PropTypes.string,
  slug: PropTypes.string,
  lastPageNum: PropTypes.number,
  seenPages: PropTypes.array,
  salesBanner: PropTypes.object
};

AdvisorsList.defaultProps = {
  advisors: [],
  theme: 'searchResult',
  analytics: null,
  page: 1,
  isLastPage: true,
  loadAdvisors: null,
  path: null,
  loading: null,
  params: null,
  slug: null,
  lastPageNum: null,
  seenPages: [],
  salesBanner: null
};

export default AdvisorsList;
