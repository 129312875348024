import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import withPGTranslation from '../../../../config/withPGTranslation';
import { AdvisorPropType, AdvisorDefaultProps } from '../../../../reducers/advisor_prop_types';
import classes from './classes.module.scss';
import AppLink from '../../../routes/app_link';
import { specialitiesClickSource } from '../../../../config/const';
import { specialityNameOnTwoLine } from '../../../../config/util';
import { advisorSpecialitiesTheme } from '../../../../config/themes';

function AdvisorSpecialities({
  specialities, advisorId, t
}) {
  const specialityLink = ({ spec, node }) => {
    const  { slug, params } = spec;
    return (
      <AppLink to={ {
        pathname: slug,
        state: {
          needRefresh: true,
          trackCategoryEvent: true,
          clickSource: specialitiesClickSource.advisorProfile,
          advisorId,
          params
        }
      } }
      >
        {node}
      </AppLink>
    );
  };

  const speciality = (spec) => {
    const  {
      id, backgroundColor, iconUrl, name
    } = spec;
    return (
      <div key={ id } className={ classes.speciality } suppressHydrationWarning>
        { specialityLink({
          spec,
          node: <img
            src={ iconUrl }
            className={ classes.specialityIcon }
            alt={ name }
            style={ { backgroundColor } }
            suppressHydrationWarning
          />
        }) }
        <div className={ classes.specialitiesSeparator } />
        { specialityLink({
          spec,
          node: specialityNameOnTwoLine(name, classes.spesialityTitle)
        }) }
      </div>
    );
  };

  const specialitiesContent = () => (
    <div className={ classes.descriptionHolder } suppressHydrationWarning>
      {t('advisor_profile.specialities_title')}
      <div className={ classes.specialitiesContainer } suppressHydrationWarning>
        {!(specialities || []).length
          ? (
            <Skeleton
              count={ 3 }
              containerClassName={ classes.specialitySkeletonContainer }
              className={ classes.specialitySkeleton }
            />
          ) :  specialities.map((s) => speciality(s))}
      </div>
    </div>
  );

  return specialitiesContent();
}

AdvisorSpecialities.propTypes = {
  advisorId: AdvisorPropType.id,
  specialities: AdvisorPropType.specialities,
  t: PropTypes.func.isRequired
};

AdvisorSpecialities.defaultProps = {
  ...AdvisorDefaultProps,
  theme: advisorSpecialitiesTheme.default
};

export default withPGTranslation(AdvisorSpecialities);
